<template>
  <div id="property-form-ad-details">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="180px"
      size="small"
    >
      <el-col>
        <h1>Auto-Renew</h1>

        <el-form-item label="Auto-Renew" prop="property.config_override">
          <el-select v-model="form.property.config_override">
            <el-option
              v-for="option in autoRenewOverrideOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <h1>Custom Titles</h1>

        <el-form-item
          class="title-behavior"
          label="Behavior"
          prop="title_setting"
        >
          <el-select v-model="form.title_setting">
            <el-option
              v-for="option in titleSettingOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Title(s)">
          <list-input
            textarea
            :value="form.title"
            :rules="rules.title"
            prop="title"
          />
        </el-form-item>

        <h1>Ad Body</h1>

        <el-form-item label="Romance Text">
          <list-input
            textarea
            :value="form.romance_text"
            :rules="rules.romance_text"
            prop="romance_text"
          />
        </el-form-item>

        <h1>Headers and Footers</h1>
        <el-alert
          title="If Headers/Footers/Disclaimers have multiple entries PostEngine will cycle through them, using only one for each post."
          type="info"
          show-icon
          :closable="false"
        />

        <el-form-item label="Disclaimer(s)">
          <list-input
            textarea
            :value="form.disclaimer"
            :rules="rules.disclaimer"
            prop="disclaimer"
          />
        </el-form-item>

        <el-form-item label="Header(s)">
          <list-input
            textarea
            :value="form.header"
            :rules="rules.header"
            prop="header"
          />
        </el-form-item>

        <el-form-item label="Footer(s)">
          <list-input
            textarea
            :value="form.footer"
            :rules="rules.footer"
            prop="footer"
          />
        </el-form-item>

        <h1>Amenities</h1>

        <el-form-item label="Amenities (property)">
          <list-input
            textarea
            :value="form.amenities_property"
            :rules="rules.amenity"
            prop="amenities_property"
          />
        </el-form-item>

        <el-form-item label="Amenities (unit)">
          <list-input
            textarea
            :value="form.amenities_unit"
            :rules="rules.amenity"
            prop="amenities_unit"
          />
        </el-form-item>

        <el-form-item label="Rent Period" prop="rent_period">
          <el-select v-model="form.rent_period" placeholder="Unknown">
            <el-option
              v-for="option in rentPeriodOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Dog Policy" prop="dog_policy">
          <el-select v-model="form.dog_policy" placeholder="Unknown">
            <el-option
              v-for="option in dogPolicyOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Cat Policy" prop="cat_policy">
          <el-select v-model="form.cat_policy" placeholder="Unknown">
            <el-option
              v-for="option in catPolicyOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Parking" prop="parking">
          <el-select v-model="form.parking" placeholder="Unknown">
            <el-option
              v-for="option in parkingOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Air Conditioning" prop="air_conditioning">
          <el-select v-model="form.air_conditioning" placeholder="Unknown">
            <el-option
              v-for="option in airConditioningOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="EV Charging" prop="ev_charging">
          <el-select v-model="form.ev_charging" placeholder="Unknown">
            <el-option
              v-for="option in evChargingOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Laundry" prop="laundry">
          <el-select v-model="form.laundry" placeholder="Unknown">
            <el-option
              v-for="option in laundryOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Smoking Policy" prop="smoking_policy">
          <el-select v-model="form.smoking_policy" placeholder="Unknown">
            <el-option
              v-for="option in smokingPolicyOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Furnished" prop="furnished">
          <el-select v-model="form.furnished" placeholder="Unknown">
            <el-option
              v-for="option in furnishedOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Wheelchair Accessible" prop="wheelchair_access">
          <el-select v-model="form.wheelchair_access" placeholder="Unknown">
            <el-option
              v-for="option in wheelchairAccessOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Housing Type" prop="housing_type">
          <el-select v-model="form.housing_type" placeholder="Unknown">
            <el-option
              v-for="option in housingTypes"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Email Policy" prop="email_policy">
          <el-select v-model="form.email_policy" placeholder="Unknown">
            <el-option
              v-for="option in emailPolicyOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <h1>Location</h1>

        <el-form-item
          label="Region"
          prop="major_region"
        >
          <el-select
            v-model="form.major_region"
            placeholder="Unknown"
            filterable
            clearable
            @change="onRegionChange"
            @clear="clearSubRegions()"
          >
            <el-option
              v-for="region in regions"
              :key="region.id"
              :label="region.name"
              :value="region.name"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="form.major_region && subRegions.length"
          label="Sub Region"
          prop="sub_region"
        >
          <el-select
            v-model="form.sub_region"
            placeholder="Unknown"
            filterable
            clearable
            @change="clearSubSubRegions()"
          >
            <el-option
              v-for="region in subRegions"
              :key="region.id"
              :label="region.name"
              :value="region.name"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="form.sub_region && subSubRegions.length"
          label="Sub-sub Region"
          prop="sub_sub_region"
        >
          <el-select
            v-model="form.sub_sub_region"
            placeholder="Unknown"
            filterable
            clearable
          >
            <el-option
              v-for="region in subSubRegions"
              :key="region.id"
              :label="region.name"
              :value="region.name"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Location(s)">
          <list-input
            textarea
            :value="form.location"
            :rules="rules.location"
            prop="location"
          />
        </el-form-item>

        <!-- <h1>Craigslist Contact Information</h1>

        <el-form-item label="Phone Number" prop="phone_number">
          <el-input v-model="form.phone_number" />
        </el-form-item>

        <el-form-item label="Phone Extension" prop="phone_extension">
          <el-input v-model="form.phone_extension" />
        </el-form-item>

        <el-form-item label="Accept Phone Calls" prop="contact_by_phone">
          <el-select v-model="form.contact_by_phone">
            <el-option
              v-for="reason in contactByOptions"
              :key="reason.value"
              :label="reason.label"
              :value="reason.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Accept Text Messages" prop="contact_by_text">
          <el-select v-model="form.contact_by_text">
            <el-option
              v-for="reason in contactByOptions"
              :key="reason.value"
              :label="reason.label"
              :value="reason.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Contact Name" prop="contact_name">
          <el-input v-model="form.contact_name" />
        </el-form-item> -->
      </el-col>
      <el-col :span="24">
        <photo-upload
          v-if="permissions.canViewPhotos"
          :disable-change="!permissions.canManagePhotos"
          :photos="photos"
          @change="handlePhotoChange"
        />
      </el-col>
    </el-form>
  </div>
</template>

<script>
import RooofAPI from '@/services/api/rooof'
import CraigslistAPI from '@/services/api/craigslist'
import ListInput from '@/components/forms/ListInput'
import PhotoUpload from '@/components/forms/PhotoUpload'
import { mapGetters } from 'vuex'
import { constants } from '@/utils/constants'

export default {
  name: 'PropertyFormPosting',
  components: {
    'list-input': ListInput,
    'photo-upload': PhotoUpload
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      permissions: {
        canViewPhotos: false,
        canManagePhotos: false
      },
      photos: [],
      selectedRegion: null,
      rules: {
        sub_region: [
          { required: true, message: 'This field is required', trigger: 'change' }
        ],
        sub_sub_region: [
          { required: true, message: 'This field is required', trigger: 'change' }
        ],
        location: [
          { max: 128, message: 'Location cannot exceed 128 characters', trigger: 'blur' }
        ],
        title: [
          { max: 73, message: 'Title cannot exceed 73 characters', trigger: 'blur' }
        ],
        romance_text: [
          { max: 5000, message: 'Romance text cannot exceed 5000 characters', trigger: 'blur' }
        ],
        disclaimer: [
          { max: 1000, message: 'Disclaimer cannot exceed 1000 characters', trigger: 'blur' }
        ],
        header: [
          { max: 1000, message: 'Header cannot exceed 1000 characters', trigger: 'blur' }
        ],
        footer: [
          { max: 1000, message: 'Footer cannot exceed 1000 characters', trigger: 'blur' }
        ],
        amenity: [
          { max: 1000, message: 'Amenity cannot exceed 1000 characters', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      regions: 'nestedRegions'
    }),
    /**
     * Get all the sub-regions for the currently selected major region.
     *
     * @returns {Array}
     */
    subRegions () {
      if (!this.selectedRegion) {
        return []
      }
      return this.selectedRegion.subRegions
    },
    /**
     * Get all the sub-sub-regions for the currently selected sub-region.
     *
     * @returns {Array}
     */
    subSubRegions () {
      if (!this.selectedRegion || !this.form.sub_region) {
        return []
      }
      const region = this.selectedRegion.subRegions.find(el => el.name === this.form.sub_region)
      return region.subSubRegions
    }
  },
  created () {
    this.contactByOptions = constants.contactBy
    this.emailPolicyOptions = constants.emailPolicy
    this.laundryOptions = constants.laundry
    this.parkingOptions = constants.parking
    this.rentPeriodOptions = constants.rentPeriod
    this.dogPolicyOptions = constants.dogPolicy
    this.catPolicyOptions = constants.catPolicy
    this.smokingPolicyOptions = constants.smokingPolicy
    this.wheelchairAccessOptions = constants.wheelchairAccess
    this.furnishedOptions = constants.furnished
    this.housingTypes = constants.housingTypes
    this.evChargingOptions = constants.evCharging
    this.airConditioningOptions = constants.airConditioning
    this.autoRenewOverrideOptions = constants.autoRenewOverride
    this.titleSettingOptions = constants.titleSetting

    if (this.form.major_region) {
      this.selectedRegion = this.regions.find(el => el.name === this.form.major_region) || null
    }

    if (this.form.property.id) {
      this.loadPhotos()
    }
  },
  methods: {
    /**
     * Determine if the current form is valid.
     *
     * @returns {Promise}
     */
    validate () {
      return new Promise(resolve => {
        this.$refs['form'].validate(valid => resolve(valid))
      })
    },
    /**
     * onChange handler for major region select.
     *
     * @param {String} - the selected value
     */
    onRegionChange (value) {
      this.selectedRegion = this.regions.find(el => el.name === value) || null
      this.clearSubRegions()
    },
    /**
     * Clears all sub-regions and sub-sub-regions.
     */
    clearSubRegions () {
      this.form.sub_region = ''
      this.form.sub_sub_region = ''
    },
    /**
     * Clears all sub-sub-regions.
     */
    clearSubSubRegions () {
      this.form.sub_sub_region = ''
    },
    /**
    * Determines the user's level of access to syndication photos
    * and fetches the list of photos from the API if appropriate.
    */
    async loadPhotos () {
      try {
        const permissions = await RooofAPI.properties.permissionsList(this.$route.params.id)
        this.permissions.canViewPhotos = permissions.includes('view_property_syndication_photos')
        this.permissions.canManagePhotos = permissions.includes('manage_property_syndication_photos')
        if (this.permissions.canViewPhotos) {
          this.photos = await CraigslistAPI.syndication_photos.list(this.$route.params.id, 'properties')
          this.$set(this.form, 'photos', { added: [], removed: [] })
        }
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
    * Update added and removed photo lists to reflect changes.
    */
    handlePhotoChange (changes) {
      this.form.photos.added = changes.added
      this.form.photos.removed = changes.removed
    }
  }
}
</script>

<style scoped>
h1:first-of-type {
  margin-top: 0;
}
.el-alert {
  margin-bottom: 1em;
}
.title-behavior .el-select {
  width: 380px;
}
</style>
