<template>
  <div id="subscription-invoice-summary">
    <div v-if="latestInvoice">
      Last invoice sent on <span class="value">{{ sendDate }}</span>
      for the amount of <span class="value">${{ amount }}</span>
      <el-tag :type="tagType" size="mini">
        {{ status }}
      </el-tag>
    </div>
  </div>
</template>

<script>
import InvoicesAPI from '@/services/api/invoices'

export default {
  name: 'SubscriptionInvoiceSummary',
  data () {
    return {
      invoices: []
    }
  },
  computed: {
    latestInvoice () {
      return this.invoices.length > 0 ? this.invoices[this.invoices.length - 1] : null
    },
    sendDate () {
      return this.latestInvoice ? this.latestInvoice.date : '(none)'
    },
    amount () {
      return this.latestInvoice ? this.latestInvoice.total : '(none)'
    },
    status () {
      return this.latestInvoice ? this.latestInvoice.status : '(none)'
    },
    tagType () {
      switch (this.status) {
        case 'sent':
          return ''
        case 'overdue':
          return 'danger'
        case 'paid':
          return 'success'
        case 'unpaid':
          return 'info'
        case 'partially_paid':
          return 'warning'
        default:
          return ''
      }
    }
  },
  async created () {
    this.invoices = await InvoicesAPI.property.retrieve(this.$route.params.id)
  }
}
</script>

<style scoped>
#subscription-invoice-summary {
  text-align: right;
  padding-bottom: 15px;
}
.value {
  font-weight: bold;
}
.el-tag {
  margin-left: 10px;
}
</style>
