<template>
  <div>
    <el-select
      value=""
      :remote-method="fetchCraigslistAccounts"
      :loading="loading"
      placeholder="Type to search"
      filterable
      remote
      @input="handleAddItem"
    >
      <template #empty>
        <div class="empty">
          <el-button type="text" @click="$emit('create-account', searchValue)">
            Add new craigslist account
          </el-button>
          <p class="el-select-dropdown__empty">
            {{ loading ? 'Loading...' : 'No Data' }}
          </p>
        </div>
      </template>
      <li class="btn-container">
        <el-button type="text" @click="$emit('create-account', searchValue)">
          Add new craigslist account
        </el-button>
      </li>
      <el-option
        v-for="account in options"
        :key="account.email"
        :label="account.email"
        :value="account"
      >
        <span>
          {{ account.email }}
        </span>
        <el-tag size="mini" :type="account.status === 'active' ? 'success' : 'info'">
          {{ account.status }}
        </el-tag>
      </el-option>
    </el-select>
    <div class="cl-accounts-list">
      <div
        v-for="account in selectedAccounts"
        :key="account.email"
        class="cl-accounts-list-item"
      >
        {{ account.email }}
        <el-tag size="mini" :type="account.status === 'active' ? 'success' : 'info'">
          {{ account.status }}
        </el-tag>
        <span class="action-buttons">
          <el-button
            v-if="showCopyButtons"
            icon="el-icon-document-copy"
            size="mini"
            round
            @click.stop="copyFieldToClipboard(account, 'email')"
          >
            Email
          </el-button>
          <el-button
            v-if="showCopyButtons"
            icon="el-icon-document-copy"
            size="mini"
            round
            @click.stop="copyFieldToClipboard(account, 'password')"
          >
            Password
          </el-button>
          <el-button
            v-if="showEditButton"
            icon="el-icon-edit"
            size="mini"
            round
            @click.stop="$emit('edit-account', account.email)"
          />
          <el-button
            icon="el-icon-close"
            size="mini"
            round
            @click.stop="handleRemoveItem(account)"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import CraigslistAPI from '@/services/api/craigslist'

export default {
  name: 'CraigslistAccountMultiSelect',
  props: {
    selected: {
      type: Array,
      required: true
    },
    showCopyButtons: {
      type: Boolean,
      default: true
    },
    showEditButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      loading: false,
      options: [],
      searchValue: '',
      selectedAccounts: []
    }
  },
  watch: {
    selected: {
      immediate: true,
      async handler (selected) {
        const promises = []
        for (const email of selected) {
          promises.push(CraigslistAPI.accounts.retrieve(email))
        }
        const response = await Promise.all(promises)
        this.selectedAccounts = response
      }
    }
  },
  methods: {
    /**
     * Copies either an email or password from a given CL account to the users clipboard
     *
     * @param {String} account - account to retrieve info from
     * @param {String} field - field to copy from CL account object. Must be either 'email' or 'password'
     */
    async copyFieldToClipboard (account, field) {
      try {
        await navigator.clipboard.writeText(account[field])
        this.$emit('display-message', `Copied ${field} to clipboard for Craigslist Account "${account.email}"`)
      } catch {
        this.$emit('display-message', `Unable get account information for ${account.email}`)
      }
    },
    /**
     * Adds a craigslist account
     *
     * @param {String} account - Craigslist account to add to property
     */
    handleAddItem (account) {
      const exists = this.selectedAccounts.find(selectedAccount => selectedAccount.email === account.email)
      if (exists) {
        this.$emit('display-message', `Craigslist Account "${account.email}" is already selected`)
        return
      }
      this.$emit('input', this.selectedAccounts.concat(account).map(account => account.email))
    },
    /**
     * Removes associated Craigslist account from property.
     *
     * @param {String} account - Account to remove from property
     */
    handleRemoveItem (account) {
      const selectedAccounts = this.selectedAccounts.filter(selectedAccount => selectedAccount.email !== account.email)
      const existsInOptions = this.options.find(acc => acc.email === account.email)
      if (!existsInOptions) {
        this.options.unshift(account)
      }
      this.$emit('input', selectedAccounts.map(account => account.email))
    },
    /**
     * Query the api for a list of craigslist accounts as
     * the user types into the search input.
     *
     * These will be used as the options list in the select
     * input.
     *
     * @param {String} query - craigslist account email
     */
    async fetchCraigslistAccounts (query) {
      this.searchValue = query
      if (!query) {
        this.options = []
        return
      }
      this.loading = true
      const params = {
        q: query,
        has_group: false,
        has_property: false
      }
      this.options = await CraigslistAPI.accounts.list(params)
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.action-buttons {
  float: right;
  padding-left: 4px;
}
.el-select {
  width: 100%;
}

.btn-container {
  padding: 0 20px;
}

.empty {
  padding: 10px 20px;
}

.el-button--text:hover {
  text-decoration: underline;
}

.cl-accounts-list-item {
  margin: 5px  0;
}
</style>
