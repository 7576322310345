<template>
  <el-form :model="form" @submit.native.prevent>
    <h1>Property Notes</h1>
    <text-editor
      v-model="form.property.notes"
    />
    <h1>Service Notes</h1>
    <text-editor
      v-model="form.property.service_notes"
    />
  </el-form>
</template>

<script>
import TextEditor from '@/components/forms/TextEditor'

export default {
  name: 'PropertyFormNotes',
  components: {
    TextEditor
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form {
  // overriding padding from parent element
  margin: 0 -15px;
  width: calc(100% + 30px) !important;
}
.text-editor {
  min-height: 300px;
}
h1:first-of-type {
  margin-top: 0;
}
</style>
