<template>
  <div id="account-create-form">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="130px"
      size="medium"
    >
      <el-form-item label="Email" prop="email">
        <el-input v-model="form.email" />
      </el-form-item>

      <el-form-item label="Password" prop="password">
        <el-input v-model="form.password" />
      </el-form-item>

      <el-form-item label="Region" prop="region">
        <el-select
          v-model="form.region"
          placeholder="Select..."
          filterable
          clearable
        >
          <el-option
            v-for="region in regionOptions"
            :key="region.value"
            :label="region.label"
            :value="region.value"
          />
        </el-select>
      </el-form-item>
    </el-form>

    <div class="controls">
      <el-button @click="cancel">
        Cancel
      </el-button>
      <el-button type="success" @click="submit">
        Add Account
      </el-button>
    </div>
  </div>
</template>

<script>
import CraigslistAPI from '@/services/api/craigslist'

export default {
  name: 'CraigslistAccountCreateForm',
  props: {
    email: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      form: {
        email: '',
        password: '',
        region: null
      },
      rules: {
        email: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          { type: 'email', message: 'Please provide a valid email address', trigger: 'blur' },
          {
            validator: async (rule, value, callback) => {
              try {
                await CraigslistAPI.accounts.retrieve(value)
                return callback(new Error('Account with this email already exists'))
              } catch (err) {
                if (err.response.status === 404) {
                  return callback()
                }
                return callback(err)
              }
            },
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          { max: 100, message: 'Password cannot be more than 100 characters', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    regionOptions () {
      return this.$store.state.regions.major_regions.map(region => (
        { value: region.name, label: region.name }
      )).sort((a, b) => a.label.localeCompare(b.label))
    }
  },
  watch: {
    email: {
      handler (value) {
        // using the email provided by the user
        this.form.email = value
      },
      immediate: true
    }
  },
  methods: {
    /**
     * onClick handler for submit button.
     */
    submit () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form))
          this.$emit('submit', data)
          this.reset()
        }
      })
    },
    /**
     * onClick handler for cancel button.
     */
    cancel () {
      this.$emit('cancel')
      this.reset()
    },
    /**
     * Reset form fields and remove validation messages.
     */
    reset () {
      this.$refs['form'].resetFields()
      this.$refs['form'].clearValidate()
    }
  }
}
</script>

<style lang="scss" scoped>
#account-create-form {
  .el-form {
    width: 80%;
  }
  .controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
