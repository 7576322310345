<template>
  <div id="subscription-details">
    <div class="section">
      {{ productOrFeature.description }}
    </div>

    <div class="flex-container">
      <div class="sub-title">
        <b>Free Trial Dates:</b>
      </div>
      <div class="content">
        <div v-if="data.trial_start_date">
          <el-row type="flex">
            <el-col :span="12">
              {{ data.trial_start_date | dateString }}
              <i class="el-icon-right" />
              {{ data.trial_end_date | dateString }}
            </el-col>
          </el-row>
        </div>
        <div v-else>
          (none)
        </div>
      </div>
    </div>

    <div v-if="productOrFeature.features">
      <div class="sub-title">
        <b>Features Included:</b>
        <div v-if="productOrFeature.features.length === 0">
          (none)
        </div>
      </div>
      <div class="content">
        <div class="feature-list">
          <div
            v-for="feature in productOrFeature.features"
            :key="feature.id"
          >
            {{ feature }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="data.discount" class="sub-title content">
      <b>Discount Expires:</b> {{ data.discount_expires | formatDiscountExpires }}
    </div>

    <el-row v-if="data.reason_for_cancel" type="flex">
      <el-col :span="24">
        <div class="sub-title">
          <b>Cancellation Reason</b>
        </div>
        <div class="content">
          {{ constants.reasonForCancel.find(el => el.value === data.reason_for_cancel).label }}
          <span v-if="data.reason_for_cancel === 'other'">
            - {{ data.reason_for_cancel_other }}
          </span>
        </div>
      </el-col>
    </el-row>

    <el-row v-if="data.notes" type="flex">
      <el-col :span="24">
        <div class="sub-title">
          <b>Notes:</b>
        </div>
        <div class="content">
          {{ data.notes }}
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { constants } from '@/utils/constants'

export default {
  name: 'SubscriptionDetails',
  filters: {
    dateString (value) {
      if (!value) {
        return 'Ongoing'
      }
      return moment(value).format('MMM DD, YYYY')
    },
    formatDiscountExpires (value) {
      if (!value) {
        return 'Never'
      }
      return moment(value).format('MMM DD, YYYY')
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    valueKey: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      productOrFeature: {
        description: ''
      }
    }
  },
  computed: {
    ...mapState({
      products: state => state.products,
      features: state => state.features
    })
  },
  created () {
    this.constants = constants
  },
  mounted () {
    const type = this.valueKey === 'product' ? 'products' : 'features'
    const productOrFeature = this[type].find(obj => obj.name === this.data[this.valueKey])
    this.productOrFeature = JSON.parse(JSON.stringify(productOrFeature)) // create copy to avoid modifying the store

    if (this.productOrFeature.description === '') {
      this.productOrFeature.description = 'No description provided.'
    }
  }
}
</script>

<style>
.sub-title {
  flex: 1;
}
.section {
   padding-bottom: 20px;
}
.content {
  flex: 1;
  flex-grow: 8;
  padding-bottom: 8px;
}
.feature-list i {
  padding-right: 3px;
}
.flex-container {
  display: flex;
  justify-content: flex-start;
}
</style>
