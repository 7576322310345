<template>
  <div id="edit-dialog">
    <el-dialog
      :visible="show"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :title="title"
      width="450px"
    >
      <subscription-form
        :subscription="subscription"
        :subscription-type="subscriptionType"
        :unit-count="unitCount"
        :premium="premium"
        @close="$emit('close')"
        @submit="handleSubmit"
      />
    </el-dialog>
  </div>
</template>

<script>
/**
 * EditActionDialog
 *
 * Component for adding/editing a product or feature
 * subscription. Wraps form component in a dialog.
 *
 * Events:
 *
 * @add-subscription: triggered on form submit
 * @edit-subscription: triggered on form submit
 * @close: triggered on dialog close
 */
import SubscriptionForm from '../forms/subscription/SubscriptionForm'

export default {
  name: 'EditActionDialog',
  components: {
    'subscription-form': SubscriptionForm
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    subscription: {
      type: Object,
      default: null
    },
    subscriptionType: {
      type: String,
      required: true,
      validator: value => {
        return value === 'product' || value === 'feature'
      }
    },
    unitCount: {
      type: Number,
      default: undefined
    },
    premium: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    type () {
      return this.subscriptionType.charAt(0).toUpperCase() + this.subscriptionType.slice(1)
    },
    isEdit () {
      return this.subscription !== null
    },
    title () {
      const action = this.isEdit ? 'Edit' : 'Add'
      return `${action} ${this.type} Subscription`
    }
  },
  methods: {
    /**
     * onSubmit handler for create/edit form submit.
     *
     * @param {Object} data
     */
    handleSubmit (data) {
      if (this.isEdit) {
        this.$emit('edit-subscription', data)
      } else {
        this.$emit('add-subscription', data)
      }
      this.$emit('close')
    }
  }
}
</script>

<style>
#edit-dialog .el-dialog {
  width: 700px !important;
}
#edit-dialog .el-dialog__body {
  padding: 20px;
}
</style>
