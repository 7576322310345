import { constants } from '@/utils/constants'

function positiveNumberValidator (rule, value, callback) {
  if (!value) {
    return callback()
  }
  if (parseFloat(value) < 0) {
    return callback(new Error('Value cannot be negative'))
  }
  return callback()
}

/**
 * Schema definition.
 *
 * The calling component should pass its context
 * object so that we can use it for validation.
 *
 * @param {Object} vm - component instance
 * @param {Array} vm.data - field/value pairs
 * @returns {Object}
 */
export default function fieldConfig (vm) {
  return [
    {
      label: 'Rate',
      value: 'rate',
      render: {
        component: 'el-input'
      },
      rules: {
        validator: positiveNumberValidator, trigger: 'blur'
      }
    },
    {
      label: 'Set Up Fee',
      value: 'setup_fee',
      render: {
        component: 'el-input'
      },
      rules: {
        validator: positiveNumberValidator, trigger: 'blur'
      }
    },
    {
      label: 'Discount',
      value: 'discount',
      render: {
        component: 'el-input'
      },
      rules: {
        validator: positiveNumberValidator, trigger: 'blur'
      }
    },
    {
      label: 'Discount Type',
      value: 'discount_type',
      render: {
        component: 'el-select',
        children: [
          {
            component: 'el-option',
            options: {
              props: { label: 'Dollar', value: '$' }
            }
          },
          {
            component: 'el-option',
            options: {
              props: { label: 'Percent', value: '%' }
            }
          }
        ]
      }
    },
    {
      label: 'Discount Expires',
      value: 'discount_expires',
      render: {
        component: 'el-date-picker',
        options: {
          props: {
            type: 'date',
            placeholder: 'Choose date',
            valueFormat: 'yyyy-MM-dd'
          }
        }
      }
    },
    {
      label: 'Billing Frequency',
      value: 'billing_frequency',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: constants.billingFrequency.filter(el => el.value).map(el => {
          return {
            component: 'el-option',
            options: {
              props: { label: el.label, value: el.value }
            }
          }
        })
      }
    },
    {
      label: 'Trial Start Date',
      value: 'trial_start_date',
      render: {
        component: 'el-date-picker',
        options: {
          props: {
            type: 'date',
            placeholder: 'Choose date',
            valueFormat: 'yyyy-MM-dd'
          }
        }
      }
    },
    {
      label: 'Trial End Date',
      value: 'trial_end_date',
      render: {
        component: 'el-date-picker',
        options: {
          props: {
            type: 'date',
            placeholder: 'Choose date',
            valueFormat: 'yyyy-MM-dd'
          }
        }
      }
    },
    {
      label: 'Start Date',
      value: 'start_date',
      render: {
        component: 'el-date-picker',
        options: {
          props: {
            type: 'date',
            placeholder: 'Choose date',
            valueFormat: 'yyyy-MM-dd'
          }
        }
      }
    },
    {
      label: 'End Date',
      value: 'end_date',
      render: {
        component: 'el-date-picker',
        options: {
          props: {
            type: 'date',
            placeholder: 'Choose date',
            valueFormat: 'yyyy-MM-dd'
          }
        }
      }
    },
    {
      label: 'Reason For Cancel',
      value: 'reason_for_cancel',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: constants.reasonForCancel.map(el => {
          return {
            component: 'el-option',
            options: {
              props: { label: el.label, value: el.value }
            }
          }
        })
      },
      rules: {
        validator: (rule, value, callback) => {
          if (!value) {
            return callback()
          }
          const showingOtherReason = vm.data.some(el => el.field === 'reason_for_cancel_other')
          if (value === 'other' && !showingOtherReason) {
            return callback(new Error('Requires Reason For Cancel (other)'))
          }
          return callback()
        }
      }
    },
    {
      label: 'Reason For Cancel (other)',
      value: 'reason_for_cancel_other',
      render: {
        component: 'el-input'
      },
      rules: {
        validator: (rule, value, callback) => {
          const reasonForCancel = vm.data.find(el => el.field === 'reason_for_cancel')
          if (reasonForCancel === undefined) {
            return callback(new Error('Requires Reason For Cancel'))
          }
          if (reasonForCancel.value !== 'other') {
            return callback(new Error('Cancellation reason must be "Other"'))
          }
          if (!value) {
            return callback(new Error('This field is required'))
          }
          return callback()
        }
      }
    },
    {
      label: 'Notes',
      value: 'notes',
      render: {
        component: 'el-input',
        options: {
          props: { type: 'textarea', rows: 3 }
        }
      }
    }
  ]
}
