<template>
  <div id="subscriptions-header">
    <el-row type="flex" justify="space-between">
      <el-col :span="12">
        <div class="action-menu">
          <div class="selected-info">
            {{ selected.length }} items selected
          </div>
          <div>
            <el-select
              v-if="selected.length"
              v-model="action"
              size="mini"
              placeholder="Action"
              clearable
            >
              <el-option label="Update selected items" value="update" />
              <el-option label="Delete selected items" value="delete" />
              <el-option label="Cancel subscriptions" value="cancel" />
            </el-select>
            <el-button
              v-if="selected.length && action"
              type="success"
              size="mini"
              @click="triggerAction"
            >
              Go
            </el-button>
          </div>
        </div>
      </el-col>
      <el-col v-if="isUpdate" :span="12">
        <invoice-summary />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import SubscriptionInvoiceSummary from './SubscriptionInvoiceSummary'
import { enums } from '@/utils/constants'

export default {
  name: 'SubscriptionsHeader',
  components: {
    'invoice-summary': SubscriptionInvoiceSummary
  },
  props: {
    selected: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      action: null
    }
  },
  computed: {
    isUpdate () {
      return this.$route.name === 'PropertyEdit'
    }
  },
  methods: {
    /**
     * onClick handler for action menu button.
     */
    triggerAction () {
      const uncancellable = [
        enums.status.PENDING,
        enums.status.INACTIVE,
        enums.status.INVALID
      ]

      switch (this.action) {
        case 'cancel':
          if (this.selected.some(subscription => uncancellable.includes(subscription.status))) {
            this.$message({
              message: 'One or more of your subscriptions cannot be cancelled either because they do not have a start date or because their end date is in the past.',
              type: 'error',
              showClose: true,
              duration: 5000
            })
          } else {
            this.$emit('on-cancel', this.selected)
          }
          break
        case 'delete':
          this.$emit('on-delete', this.selected)
          break
        case 'update':
          this.$emit('on-update', this.selected)
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.action-menu {
  display: flex;
  align-items: center;
  min-height: 28px;

  .el-select {
    margin-left: 10px;
  }
  .el-button {
    margin-left: 5px;
  }
}
.selected-info {
  font-size: 14px;
  color: #808080
}
</style>
