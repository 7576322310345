<template>
  <el-form
    :model="form"
    :rules="rules"
    label-width="170px"
    size="small"
    class="link-forwarding"
  >
    <el-row
      type="flex"
      align="middle"
      justify="space-between"
      class="link-forwarding__header"
    >
      <h1>Link Forwarding</h1>
      <el-switch
        v-if="company.short_url_domain && hasSubscription"
        v-model="form.property.enable_redirect_links"
        active-text="ON"
        inactive-text="OFF"
      />
    </el-row>
    <el-alert
      v-if="!company.short_url_domain"
      type="info"
      show-icon
      :closable="false"
    >
      <template #title>
        Link forwarding cannot be set up until a domain has been
        added for the company:
        <b @click="$router.push({ name: 'CompanyEdit' })">
          {{ company.human_name }}
        </b>
      </template>
    </el-alert>
    <el-alert
      v-if="!hasSubscription"
      type="info"
      show-icon
      :closable="false"
    >
      <template #title>
        Link forwarding cannot be set up without a <strong>Lead Tracking (website)</strong> subscription.
      </template>
    </el-alert>
    <div class="form-content">
      <div
        v-if="!company.short_url_domain || !hasSubscription"
        class="disable-overlay"
      />
      <section>
        <el-form-item label="Rooof Short URL" prop="property.short_url_slug">
          <el-input v-model="form.property.short_url_slug">
            <template #prepend>
              https://{{ company.short_url_domain }}/<i>Craigslist Ad ID</i>/
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="Short URL Preview">
          <i class="url-preview">
            https://{{ company.short_url_domain }}/00000000/{{ form.property.short_url_slug }}
          </i>
        </el-form-item>
      </section>
      <section>
        <el-form-item label="Custom Destination URL" prop="property.short_url_target">
          <el-input v-model="form.property.short_url_target" :placeholder="form.property.website_url || 'https://www.myproperty.com/'" />
        </el-form-item>
        <el-form-item label="Tracking Parameters" prop="property.custom_tracking_parameters">
          <el-input v-model="form.property.custom_tracking_parameters" placeholder="utm_source=craigslist&utm_campaign=rooof-postengine" />
        </el-form-item>
        <el-form-item label="Redirect URL Preview">
          <i v-if="redirectUrl" class="url-preview">
            {{ redirectUrl }}
          </i>
          <i v-else class="url-preview--disabled">
            (Please configure property website URL or enter a Custom Destination URL above)
          </i>
        </el-form-item>
      </section>
    </div>
  </el-form>
</template>

<script>
import { enums } from '@/utils/constants'
import { getFeatureSet } from '@/utils/subscriptions'
import { validators } from '@/utils/element'
export default {
  name: 'PropertyFormLinkForwarding',
  props: {
    form: {
      type: Object,
      required: true
    },
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      rules: {
        'property.short_url_target': [
          { type: 'url', message: 'Please provide a valid URL', trigger: 'blur' }
        ],
        'property.custom_tracking_parameters': [
          { validator: validators.trackingParameterValidator, message: 'Tracking parameters are invalid, must begin with either "/" or "?"', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    redirectUrl () {
      if (!this.form.property.website_url &&
          !this.form.property.short_url_target &&
          !this.company.short_url_fallback_destination) {
        return null
      }
      const url = this.form.property.short_url_target || this.form.property.website_url || this.company.short_url_fallback_destination
      let trackingParameters = this.form.property.custom_tracking_parameters || this.company.short_url_params

      if (url.substring(url.length - 1) === '/' && trackingParameters.substring(0, 1) === '/') {
        trackingParameters = trackingParameters.substring(1)
      }
      if (url.includes('?') && trackingParameters.substring(0, 1) === '?') {
        trackingParameters = '&' + trackingParameters.substring(1)
      }
      return url + (trackingParameters || '')
    },
    /**
     * Returns true if the property has a non inactive product subscription with 'Lead Tracking (website)'
     * as an included feature, or a non inactive feature subscription to it.
     */
    hasSubscription () {
      const featureSet = getFeatureSet(this.form.property, status => status !== enums.status.INACTIVE)
      return featureSet.has(enums.features.REDIRECT_LINKS)
    }
  }
}
</script>

<style lang="scss" scoped>
$color-primary: #1a7dc6;
$color-border: #dcdfe6;
$color-disabled: #bbb;
.form-wrapper {
  .el-form.link-forwarding {
    width: 100%;
  }
}
.link-forwarding {
  .link-forwarding__header {
    margin-bottom: 1.17em;
    h1 {
      margin: 0;
    }
  }
  .el-alert {
    margin-bottom: 1em;
    background-color: rgba($color-primary, 0.2);
    color: $color-primary;
    b:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .form-content {
    position: relative;
    .disable-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: #fff;
      opacity: 0.5;
    }
    section {
      &:not(:last-of-type) {
        margin-bottom: 2em;
      }
      .url-preview {
        color: $color-primary;
      }
      .url-preview--disabled {
        color: $color-disabled;
      }
    }
  }
}
</style>
