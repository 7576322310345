<template>
  <div id="property-form-contact">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="180px"
      size="small"
    >
      <el-col>
        <el-row
          class="email-handler-header"
          type="flex"
          align="middle"
          justify="space-between"
        >
          <h1>Email Handler</h1>
          <el-button
            icon="el-icon-s-tools"
            size="small"
            @click="$router.push({name: 'PropertyEmailhandlerConfiguration'})"
          >
            Configure
          </el-button>
        </el-row>
        <el-form-item label="Craigslist Accounts">
          <account-select
            :selected="form.craigslist_accounts"
            @input="form.craigslist_accounts = $event"
            @create-account="handleShowCreateAccountForm($event)"
            @edit-account="handleShowEditDialog"
            @display-message="displayMessage($event)"
          />
        </el-form-item>

        <el-form-item label="Email Delivery Lead" prop="property.email_delivery_lead">
          <el-input v-model="form.property.email_delivery_lead" disabled />
        </el-form-item>

        <el-form-item label="Email Delivery Lead Copy" prop="property.email_delivery_lead_copy">
          <el-input v-model="form.property.email_delivery_lead_copy" disabled />
        </el-form-item>

        <el-form-item label="Email Delivery Property" prop="property.email_delivery_property">
          <el-input v-model="emailDelivery" disabled />
        </el-form-item>

        <h1>3rd Party Integrations</h1>

        <el-form-item label="Tracking Phone" prop="property.phone_leasing">
          <el-input v-model="form.property.phone_leasing" />
        </el-form-item>

        <el-form-item label="Tracking Email" prop="property.email_leasing">
          <el-input v-model="form.property.email_leasing" />
        </el-form-item>

        <h1>CallRail</h1>

        <el-form-item label="CallRail Phone" prop="property.phone_callrail">
          <el-input v-model="form.property.phone_callrail" />
        </el-form-item>

        <link-forwarding :form="form" :company="company" />

        <h1>Price Updater</h1>

        <el-form-item label="Post Update ID" prop="property.post_update_id">
          <el-input v-model="form.property.post_update_id" />
        </el-form-item>

        <h1>Knock CRM</h1>

        <el-form-item label="Community ID" prop="property.knock_community_id">
          <el-input v-model="form.property.knock_community_id" />
        </el-form-item>
      </el-col>
    </el-form>

    <form-dialog
      :show="showCreateDialog"
      :show-close="true"
      title="Add Craigslist Account"
      width="500px"
      @close="showCreateDialog = false"
    >
      <account-create-form
        :email="createCraigslistAccountEmail"
        @submit="createCraigslistAccount"
        @cancel="showCreateDialog = false"
      />
    </form-dialog>

    <form-dialog
      :show="showEditDialog"
      :show-close="true"
      title="Edit Craigslist Account"
      width="650px"
      @close="showEditDialog = false"
    >
      <account-edit-form
        :email="editTarget"
        @submit="editCraigslistAccount"
      />
    </form-dialog>
  </div>
</template>

<script>
import FormDialog from '@/components/dialogs/FormDialog'

import LinkForwarding from '../PropertyFormLinkForwarding'
import CraigslistAccountMultiSelect from './CraigslistAccountMultiSelect'
import CraigslistAccountCreateForm from './CraigslistAccountCreateForm'
import CraigslistAccountForm from '@/views/craigslist-accounts/_components/CraigslistAccountForm'

import CraigslistAPI from '@/services/api/craigslist'
import { enums } from '@/utils/constants'
import { getFeatureSet } from '@/utils/subscriptions'

export default {
  name: 'PropertyFormLeadTracking',
  components: {
    'account-select': CraigslistAccountMultiSelect,
    'form-dialog': FormDialog,
    'account-create-form': CraigslistAccountCreateForm,
    'account-edit-form': CraigslistAccountForm,
    'link-forwarding': LinkForwarding
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      rules: {
        'property.email_leasing': [
          { type: 'email', message: 'Please provide a valid email address', trigger: 'blur' }
        ],
        'property.phone_leasing': [
          { max: 31, message: 'Phone number cannot be more than 31 characters', trigger: 'blur' }
        ],
        'property.phone_callrail': [
          { max: 31, message: 'Phone number cannot be more than 31 characters', trigger: 'blur' }
        ],
        'property.email_delivery_lead': [
          { type: 'email', message: 'Please provide a valid email address', trigger: 'blur' }
        ],
        'property.email_delivery_lead_copy': [
          { type: 'email', message: 'Please provide a valid email address', trigger: 'blur' }
        ],
        'property.email_delivery_property': [
          { type: 'email', message: 'Please provide a valid email address', trigger: 'blur' }
        ],
        'property.crm_id': [
          { max: 100, message: 'CRM ID cannot be more than 100 characters', trigger: 'blur' }
        ],
        'property.post_update_id': [
          { max: 100, message: 'Post Update ID cannot be more than 100 characters', trigger: 'blur' }
        ],
        'property.knock_community_id': [
          { max: 16, message: 'Community ID cannot be more than 16 characters', trigger: 'blur' }
        ]
      },
      showCreateDialog: false,
      createCraigslistAccountEmail: '',
      showEditDialog: false,
      editTarget: undefined
    }
  },
  computed: {
    /**
     * Display caasnonactionable@postengine.com as email_delivery_property if property has
     * an active subscription to Posting Service feature
     */
    emailDelivery () {
      if (!this.form.property.features_list) {
        return ''
      }
      const featureSet = getFeatureSet(this.form.property, status => [enums.status.ACTIVE, enums.status.OFFBOARDING, enums.status.TRIAL, enums.status.PRE_TRIAL].includes(status))
      if (featureSet.has(enums.features.POSTING_SERVICE)) {
        return 'caasnonactionable@postengine.com'
      }
      return this.form.property.email_delivery_property
    }
  },
  methods: {
    /**
     * Determine if the current form is valid.
     *
     * @returns {Promise}
     */
    validate () {
      return new Promise(resolve => {
        this.$refs['form'].validate(valid => resolve(valid))
      })
    },
    /**
     * Display the craigslist account edit form
     * in a dialog after setting the edit target.
     *
     * @param {String} email - craigslist account email
     */
    handleShowEditDialog (email) {
      this.editTarget = email
      this.showEditDialog = true
    },
    /**
     * Send request to create a new craigslist
     * account to the api.
     *
     * @param {Object} data
     * @param {String} data.email
     * @param {String} data.password
     * @param {(Number|null)} data.region
     */
    async createCraigslistAccount (data) {
      try {
        const payload = {
          ...data,
          status: 'active',
          company: this.company.id,
          properties: [ this.$route.params.id ]
        }
        const response = await CraigslistAPI.accounts.create(payload)
        this.form.craigslist_accounts.push(response.email)
        this.showCreateDialog = false
        this.displayMessage(`Account <strong>${data.email}</strong> created`)
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      }
    },
    /**
     * Send request to edit an existing craigslist
     * account to the api.
     *
     * @param {Object} data
     */
    async editCraigslistAccount (data) {
      try {
        await CraigslistAPI.accounts.update(this.editTarget, data)
        if (!data.properties.includes(this.form.property.id) && this.form.craigslist_accounts.includes(data.email)) {
          this.form.craigslist_accounts = this.form.craigslist_accounts.filter(email => email !== data.email)
        }
        this.showEditDialog = false
        this.displayMessage(`Account <strong>${data.email}</strong> updated`)
      } catch (err) {
        if (err.response) {
          const needsAssocation = err.response.status === 400 &&
            err.response.data.password &&
            err.response.data.password.some(
              msg => msg === 'Cannot encrypt password without any company associations'
            )
          const details = needsAssocation
            ? 'Please associate the Craigslist Account with a property before setting a password.'
            : err.response.data
          this.$rfAlert.error(this, err, details)
        } else {
          this.$rfAlert.error(this, err, null)
        }
      }
    },
    /**
     * Display a confirmation message.
     *
     * @param {String} message
     */
    displayMessage (message) {
      this.$message({
        dangerouslyUseHTMLString: true,
        message: message,
        type: 'success',
        duration: 3500
      })
    },
    /**
     * Show the create craigslist account form with the email the user searched for
     *
     * @param {String} email: Email to use in the craigslist account form
     */
    handleShowCreateAccountForm (email) {
      this.createCraigslistAccountEmail = email
      this.showCreateDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
h1:first-of-type {
  margin-top: 0;
}
.email-handler-header {
  margin-bottom: 1.17em;
  h1 {
    margin: 0;
  }
}
</style>
