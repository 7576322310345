<template>
  <el-form-item label="Photos" class="photo-upload">
    <el-upload
      ref="upload"
      :multiple="true"
      list-type="picture-card"
      :file-list="photos"
      action=""
      accept=".jpeg,.jpg,.png,.gif"
      :auto-upload="false"
      :limit="15"
      :disabled="disableChange"
      :on-change="handlePhotoChange"
    >
      <i class="el-icon-upload" />
      <div slot="file" slot-scope="{file}">
        <img
          class="el-upload-list__item-thumbnail"
          :src="file.url"
          :alt="file.name"
        >
        <span class="el-upload-list__item-actions">
          <span
            v-if="!disableChange"
            class="el-upload-list__item-delete"
            @click="handlePhotoRemove(file)"
          >
            <i class="el-icon-delete" />
          </span>
        </span>
      </div>
    </el-upload>
  </el-form-item>
</template>

<script>
export default {
  name: 'PhotoUpload',
  props: {
    disableChange: {
      type: Boolean,
      required: true
    },
    photos: {
      type: Array,
      required: true
    }
  },
  methods: {
    /**
    * Updates the list of photos to add and remove.
    */
    handlePhotoChange (_, fileList) {
      this.$emit('change', {
        added: fileList.filter(x => !this.photos.find(y => y.uid === x.uid)),
        removed: this.photos.filter(x => !fileList.find(y => y.uid === x.uid))
      })
    },
    /**
    * Removes file from uploader file list.
    */
    handlePhotoRemove (file) {
      const uploader = this.$refs['upload']
      const fileList = uploader.uploadFiles
      fileList.splice(fileList.indexOf(file), 1)
      uploader.onRemove(file, fileList)
      this.handlePhotoChange(file, fileList)
    }
  }
}

</script>

<style>
.photo-upload .el-upload-list.is-disabled + .el-upload {
  display: none;
}
</style>
