<template>
  <div id="property-form-custom">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="110px"
      size="small"
    >
      <el-col>
        <h1>Integration Pattern</h1>
        <el-form-item label="Integration Pattern (regex)" prop="property.integration_pattern">
          <el-input
            v-model="form.property.integration_pattern"
            type="textarea"
          />
        </el-form-item>
        <h1>Custom Fields</h1>
        <div class="toggle">
          <el-switch
            v-model="lockProperty"
            active-color="#ff4949"
            inactive-color="#13ce66"
            inactive-text="Allow Editing"
          />
        </div>
        <el-form-item label="Custom Fields (property)" prop="property.extra">
          <el-input
            v-model="form.property.extra"
            :disabled="lockProperty"
            :rows="15"
            type="textarea"
          />
        </el-form-item>

        <div class="toggle">
          <el-switch
            v-model="lockPosting"
            active-color="#ff4949"
            inactive-color="#13ce66"
            inactive-text="Allow Editing"
          />
        </div>
        <el-form-item label="Custom Fields (posting)" prop="extra">
          <el-input
            v-model="form.extra"
            :disabled="lockPosting"
            :rows="15"
            type="textarea"
          />
        </el-form-item>
      </el-col>
    </el-form>
  </div>
</template>

<script>
import { validators } from '@/utils/element'

export default {
  name: 'PropertyFormIntegrations',
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      rules: {
        'property.integration_pattern': [
          { validator: validators.regex, trigger: 'blur' }
        ],
        'property.extra': [
          { validator: validators.json, trigger: 'blur' }
        ],
        'extra': [
          { validator: validators.json, trigger: 'blur' }
        ]
      },
      lockProperty: true,
      lockPosting: true
    }
  },
  created () {
    this.prettyPrintJSON()
  },
  methods: {
    /**
     * Determine if the current form is valid.
     *
     * @returns {Promise}
     */
    validate () {
      return new Promise(resolve => {
        this.$refs['form'].validate(valid => resolve(valid))
      })
    },
    /**
     * Pretty-print JSON input fields.
     */
    prettyPrintJSON () {
      this.form.property.extra = JSON.stringify(JSON.parse(this.form.property.extra), null, 2)
      this.form.extra = JSON.stringify(JSON.parse(this.form.extra), null, 2)
    }
  }
}
</script>

<style scoped>
.toggle {
  padding-bottom: 1em;
  text-align: right;
}
h1:first-of-type {
  margin-top: 0;
}
</style>
