<template>
  <div id="property-form-address">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      :disabled="disabled"
      label-width="180px"
      size="small"
    >
      <el-form-item label="Street Address" prop="street">
        <el-input :id="uid" v-model="form.street" />
      </el-form-item>

      <el-form-item label="City" prop="city">
        <el-input v-model="form.city" />
      </el-form-item>

      <el-form-item label="State" prop="state">
        <el-input v-model="form.state" />
      </el-form-item>

      <el-form-item label="Postal" prop="postal">
        <el-input v-model="form.postal" />
      </el-form-item>

      <el-form-item label="Country" prop="country">
        <el-input v-model="form.country" />
      </el-form-item>

      <el-form-item label="Latitude" prop="latitude">
        <el-input v-model.number="form.latitude" />
      </el-form-item>

      <el-form-item label="Longitude" prop="longitude">
        <el-input v-model.number="form.longitude" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { nanoid } from 'nanoid'
import { isNumeric, loadScript } from '@/utils'

export default {
  name: 'PropertyFormAddress',
  props: {
    form: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // uid is required to uniquely identify autocomplete input
      // when there are multiple instances of this component
      uid: nanoid(),
      autocomplete: null,
      rules: {
        street: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          { max: 300, message: 'Street cannot be more than 300 characters', trigger: 'blur' }
        ],
        city: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          { max: 100, message: 'City cannot be more than 100 characters', trigger: 'blur' }
        ],
        state: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          { max: 2, message: 'State cannot be more than 2 characters', trigger: 'blur' }
        ],
        country: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          { max: 100, message: 'Country cannot be more than 100 characters', trigger: 'blur' }
        ],
        postal: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          { max: 10, message: 'Postal code cannot be more than 10 characters', trigger: 'blur' }
        ],
        latitude: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback()
              }
              if (!isNumeric(value)) {
                return callback(new Error('Latitude must be a number'))
              }
              if (value > 90 || value < -90) {
                return callback(new Error('Latitude must be in the range +90 to -90'))
              }
              return callback()
            },
            trigger: 'blur'
          }
        ],
        longitude: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback()
              }
              if (!isNumeric(value)) {
                return callback(new Error('Longitude must be a number'))
              }
              if (value > 180 || value < -180) {
                return callback(new Error('Longitude must be in the range +180 to -180'))
              }
              return callback()
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted () {
    const config = {
      url: 'https://maps.googleapis.com/maps/api/js',
      apiKey: 'AIzaSyCipLQv8rEgvo8ukAprEU-c1tPWignv6LU',
      libraries: ['places'],
      callback: 'initGoogleMapsAPI'
    }
    this.injectScript(config)

    // Expose callback function
    window[config.callback] = this.initGoogleMapsAPI
  },
  methods: {
    /**
     * Inject the google maps client-side api script
     * if it hasn't already been fetched. If it has,
     * store the api object in local state.
     *
     * @param {Objec} config
     */
    injectScript (config) {
      if (window.google && this.autocomplete === null) {
        return this.initGoogleMapsAPI()
      }
      const url = new URL(config.url)
      url.search = new URLSearchParams({
        'key': config.apiKey,
        'libraries': config.libraries.join(','),
        'callback': config.callback
      })
      loadScript(url.toString())
    },
    /**
     * Callback function for google maps api init.
     * Turns the designated input element into an
     * autocomplete widget.
     */
    initGoogleMapsAPI () {
      const config = {
        types: [ 'address' ],
        componentRestrictions: {
          country: [ 'us', 'ca' ]
        },
        fields: [ 'name', 'address_components', 'geometry' ]
      }
      const input = document.getElementById(this.uid)
      this.autocomplete = new window.google.maps.places.Autocomplete(input, config)
      window.google.maps.event.clearInstanceListeners(input)
      window.google.maps.event.addListener(this.autocomplete, 'place_changed', this.onPlaceChanged)
    },
    /**
     * Event handler for place_changed event. Parses
     * the response object from google and sets form
     * values.
     */
    onPlaceChanged () {
      this.$refs['form'].clearValidate()
      const place = this.autocomplete.getPlace()

      if (place.hasOwnProperty('address_components')) {
        this.resetAddress()

        for (const component of place.address_components) {
          const types = component.types

          if (types.includes('street_number')) {
            this.form.street = component.short_name
          } else if (types.includes('route')) {
            // Not all addresses have a street number
            if (this.form.street !== '') {
              this.form.street += ' ' + component.short_name
            } else {
              this.form.street = component.short_name
            }
          } else if (types.includes('locality')) {
            this.form.city = component.short_name
          } else if (types.includes('administrative_area_level_1')) {
            this.form.state = component.short_name
          } else if (types.includes('country')) {
            this.form.country = component.long_name
          } else if (types.includes('postal_code')) {
            this.form.postal = component.short_name
          }
        }
        // The api only accepts 9 digit precision for lat/long
        this.form.latitude = parseFloat(place.geometry.location.lat().toFixed(6))
        this.form.longitude = parseFloat(place.geometry.location.lng().toFixed(6))
      }
    },
    /**
     * Determine if the current form is valid.
     *
     * @returns {Promise}
     */
    validate () {
      return new Promise(resolve => {
        this.$refs['form'].validate(valid => resolve(valid))
      })
    },
    /**
     * Clear address fields. Mutates prop directly.
     */
    resetAddress () {
      this.form.street = ''
      this.form.city = ''
      this.form.state = ''
      this.form.postal = ''
      this.form.country = ''
      this.form.latitude = ''
      this.form.longitude = ''
    }
  }
}
</script>

<style>
/* Remove "powered by Google" */
.pac-container:after {
  background-image: none !important;
  height: 0px;
  padding: 0;
  margin: 0;
}
</style>

<style scoped>
#autocomplete {
  width: 100%;
}
.el-form-item:last-of-type {
  margin-bottom: 0;
}
</style>
