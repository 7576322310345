<template>
  <div id="update-form">
    <div class="form-tip">
      This will update <b>{{ subscriptions.length }}</b> subscriptions.
      Which values do you want to change?
    </div>
    <el-form
      ref="update-form"
      :model="{ data }"
      label-width="50px"
    >
      <multi-field-update
        :data="data"
        :fields="fieldConfig"
      />
      <div class="form-controls">
        <el-button @click="cancel">
          Cancel
        </el-button>
        <el-button type="primary" @click="submit">
          Confirm
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
/**
 * UpdateForm
 *
 * Prompts users to select fields to update. For each
 * field, an input will be displayed which allows the
 * user to give the value for that field.
 *
 * Events:
 *
 * @close: triggers on cancel
 * @submit: triggers on form submit
 */
import MultiFieldUpdate from '@/components/forms/MultiFieldUpdate'
import fieldConfig from './_fieldConfig'

export default {
  name: 'UpdateForm',
  components: {
    'multi-field-update': MultiFieldUpdate
  },
  props: {
    subscriptions: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      data: [{
        field: null,
        value: null
      }]
    }
  },
  created () {
    this.fieldConfig = fieldConfig(this).filter(el => {
      return el.value !== 'end_date'
    })
  },
  methods: {
    /**
     * onClick handler for submit button.
     * Emits 'submit' event if form data is valid.
     */
    submit () {
      this.$refs['update-form'].validate(valid => {
        if (!valid) {
          return
        }
        // Send a copy, reset() will clear existing data
        this.$emit('submit', JSON.parse(JSON.stringify(this.data)))
        this.$emit('close')
        this.reset()
      })
    },
    /**
     * onClick handler for cancel button.
     * Resets form fields and emits 'close' event.
     */
    cancel () {
      this.$emit('close')
      this.reset()
    },
    /**
     * Reset form to default values.
     */
    reset () {
      this.$refs['update-form'].clearValidate()
      this.data = [{ field: null, value: null }]
    }
  }
}
</script>

<style scoped>
.form-controls {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}
.form-tip {
  word-break: normal;
  padding-bottom: 20px;
}
</style>
