<template>
  <div id="property-form-billing">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="180px"
      size="small"
    >
      <el-col>
        <h1>Billing Contact</h1>

        <el-form-item label="Billing Email" prop="property.billing_email">
          <el-input v-model="form.property.billing_email" />
        </el-form-item>

        <el-form-item label="Use Property Address">
          <el-checkbox v-model="sameAsProperty" @change="copyPropertyAddress()" />
        </el-form-item>

        <el-form-item prop="property.billing_address" class="billing-address">
          <address-form
            ref="address-form"
            :form="form.property.billing_address"
            :disabled="sameAsProperty"
          />
        </el-form-item>

        <h1>Billing Details</h1>

        <el-form-item label="ZOHO Customer ID" prop="property.zoho_customer_id">
          <el-input v-model="form.property.zoho_customer_id" />
          <link-button
            v-if="form.property.zoho_customer_id.length > 17"
            :href="`https://books.zoho.com/app#/contacts/${form.property.zoho_customer_id}`"
            target="_blank"
            type="primary"
          >
            {{ `https://books.zoho.com/app#/contacts/${form.property.zoho_customer_id}` }}
          </link-button>
        </el-form-item>

        <el-form-item
          v-if="permissions.canViewAgreements||isStaff"
          label="Agreements"
          prop="agreement"
        >
          <file-upload
            file-types=".pdf"
            :files="agreements"
            :loading="loadingAgreements"
            :disable-change="!(permissions.canManageAgreements||isStaff)"
            @change="handleAgreementChange"
          />
        </el-form-item>

        <el-form-item
          v-loading="loadingInvoicingGroups"
          label="Invoicing Group"
          prop="property.invoicing_group"
        >
          <el-cascader
            v-model="form.property.invoicing_group"
            separator=" > "
            :options="invoicingGroupOptions"
            :props="cascaderProps"
            clearable
          />
        </el-form-item>
      </el-col>
    </el-form>
  </div>
</template>

<script>
import PropertyFormAddress from './PropertyFormAddress'
import FileUpload from '@/components/forms/FileUpload'
import LinkButton from '@/components/buttons/LinkButton'
import RooofAPI from '@/services/api/rooof'
import InvoicesAPI from '@/services/api/invoices'
import { validators } from '@/utils/element'
import { createBlankAddress, isDuplicateAddress } from '@/utils/rooof'
import { constants } from '@/utils/constants'
import { removeEmptyChildrenFromGroups } from '@/utils/groups'

export default {
  name: 'PropertyFormBilling',
  components: {
    'address-form': PropertyFormAddress,
    'file-upload': FileUpload,
    'link-button': LinkButton
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      rules: {
        'property.billing_email': [
          { type: 'email', message: 'Please provide a valid email address', trigger: 'blur' }
        ],
        'property.billing_address': [
          { required: true, validator: validators.emptyAddress, trigger: 'blur' }
        ]
      },
      sameAsProperty: false,
      permissions: {
        canViewAgreements: false,
        canManageAgreements: false
      },
      agreements: [],
      loadingAgreements: false,
      cascaderProps: {
        expandTrigger: 'hover',
        checkStrictly: true,
        emitPath: false,
        value: 'id',
        label: 'name'
      },
      invoicingGroupOptions: [],
      loadingInvoicingGroups: false
    }
  },
  computed: {
    /**
     * Determine if the current user is staff.
     *
     * @returns {Boolean}
     */
    isStaff () {
      return this.$store.getters['auth/isStaff']
    }
  },
  async created () {
    this.constants = constants

    const propertyAddress = this.form.property.property_address
    const billingAddress = this.form.property.billing_address

    if (isDuplicateAddress(propertyAddress, billingAddress)) {
      this.sameAsProperty = true
    }
    await this.loadInvoicingGroups()
    if (this.$route.params.id) {
      await this.loadAgreements(this.$route.params.id)
    }
  },
  methods: {
    /**
     * Determine if the current form is valid.
     *
     * @returns {Promise}
     */
    validate () {
      const form = this.$refs['form']

      return new Promise(resolve => {
        form.validate(async valid => {
          if (!valid) {
            return resolve(false)
          }
          form.clearValidate()
          const addressFormComponent = this.$refs['address-form']
          const addressFormValid = await addressFormComponent.$refs['form'].validate()

          return resolve(addressFormValid)
        })
      })
    },
    /**
     * Copy property address fields to billing address.
     */
    copyPropertyAddress () {
      if (this.sameAsProperty) {
        this.form.property.billing_address = this.form.property.property_address
      } else {
        this.form.property.billing_address = createBlankAddress()
      }
    },
    /**
    * Determines the user's level of access to agreements and fetches
    * the list of agreements from the API if appropriate.
    *
    * @param {Number} propertyId - id of property
    */
    async loadAgreements (propertyId) {
      try {
        this.loadingAgreements = true
        const permissions = await RooofAPI.properties.permissionsList(propertyId)
        this.permissions.canViewAgreements = permissions.includes('view_property_agreements')
        this.permissions.canManageAgreements = permissions.includes('manage_property_agreements')
        if (this.permissions.canViewAgreements || this.isStaff) {
          this.agreements = await RooofAPI.agreements.list(propertyId, 'properties')
          this.$set(this.form, 'agreements', { added: [], removed: [] })
        }
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loadingAgreements = false
      }
    },
    /**
    * Update added and removed agreement lists to reflect changes.
    *
    * @param {Object} changes - lists of added and removed files
    */
    handleAgreementChange (changes) {
      this.form.agreements.added = changes.added
      this.form.agreements.removed = changes.removed
    },
    /**
     * Fetch invoicing groups from the API as options in the cascader.
     */
    async loadInvoicingGroups () {
      try {
        this.loadingInvoicingGroups = true
        const params = { company_id: this.$route.params.cid }
        const invoicingGroups = await InvoicesAPI.groups.list(params)
        // top level groups should always exist
        if (!invoicingGroups.length) {
          throw new Error('Missing top level group(s). Please contact support.')
        }
        this.invoicingGroupOptions = removeEmptyChildrenFromGroups(invoicingGroups[0].children)
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loadingInvoicingGroups = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h1:first-of-type {
  margin-top: 0;
}
.el-checkbox {
  margin-left: 0;
}
.billing-address > ::v-deep.el-form-item__content {
  margin-left: 0 !important;
}
.el-cascader {
  ::v-deep .el-tag__close {
    display: none;
  }
}
</style>
