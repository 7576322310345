<template>
  <div id="property-form-general">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="180px"
      size="small"
    >
      <el-col>
        <h1>Property Info</h1>
        <el-form-item label="Property Name" prop="property.name">
          <el-input v-model="form.property.name" />
        </el-form-item>

        <el-form-item label="Status">
          <el-tag :type="statusColor" size="medium">
            {{ form.property.status }}
          </el-tag>
        </el-form-item>

        <el-form-item label="Activation Code">
          <el-input
            v-model="form.property.activation_code"
            class="activation-code"
            disabled
          >
            <el-button
              slot="append"
              :disabled="!hasPostEngineSubscription"
              @click="copyActivationCode(form.property.activation_code)"
            >
              Copy
            </el-button>
          </el-input>
        </el-form-item>

        <h1>Property Websites</h1>

        <el-form-item label="Website URL" prop="property.website_url">
          <el-input v-model="form.property.website_url" />
        </el-form-item>

        <el-form-item label="Floor Plan URL(s)">
          <list-input
            :value="form.property.floor_plan_urls"
            :rules="rules['property.floor_plan_url']"
            prop="property.floor_plan_urls"
          />
        </el-form-item>

        <el-form-item label="All Available Units URL" prop="property.all_available_units_url">
          <el-input v-model="form.property.all_available_units_url" />
        </el-form-item>

        <h1>Contact Details</h1>

        <el-form-item label="Property Email" prop="property.email_contact">
          <el-input v-model="form.property.email_contact" />
        </el-form-item>

        <el-form-item label="Property Manager Email" prop="property.email_manager">
          <el-input v-model="form.property.email_manager" />
        </el-form-item>

        <el-form-item label="Property Phone" prop="property.phone_contact">
          <el-input v-model="form.property.phone_contact" />
        </el-form-item>

        <el-form-item class="property-address" prop="property.property_address">
          <address-form ref="address-form" :form="form.property.property_address" />
        </el-form-item>

        <h1>Full Service Details</h1>

        <el-alert
          v-if="!hasFullService"
          type="info"
          show-icon
          :closable="false"
        >
          <template #title>
            Property does not have a <strong>Full Service</strong> subscription.
          </template>
        </el-alert>
        <div class="full-service-content">
          <div v-if="!hasFullService" class="disable-overlay" />
          <section>
            <el-form-item label="Full Service Contact Email" prop="property.email_agency">
              <el-input v-model="form.property.email_agency" :disabled="usePropertyEmail" />
            </el-form-item>

            <el-form-item label="Use Property Email">
              <el-checkbox
                v-model="usePropertyEmail"
                :disabled="!hasFullService"
                @change="usePropertyEmailHandler"
              />
            </el-form-item>

            <el-form-item label="Full Service DM Email">
              <list-input
                :value="form.property.email_agency_decisionmaker"
                :rules="rules.email_agency_decisionmaker"
                prop="property.email_agency_decisionmaker"
              />
            </el-form-item>

            <el-form-item label="Posting Day" prop="property.posting_day">
              <el-select
                v-model="form.property.posting_day"
                clearable
                placeholder="Select..."
              >
                <el-option
                  v-for="option in postingDayOptions"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                />
              </el-select>
            </el-form-item>
          </section>
        </div>

        <h1>Additional Details</h1>

        <el-form-item label="Legal Name" prop="property.legal_name">
          <el-input v-model="form.property.legal_name" />
        </el-form-item>

        <el-form-item label="Type" prop="property.type">
          <el-select v-model="form.property.type" placeholder="Select...">
            <el-option
              v-for="option in propertyTypes"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Unit Count" prop="property.unit_count">
          <el-input-number
            v-model.number="form.property.unit_count"
            :min="0"
          />
        </el-form-item>

        <el-form-item v-loading="loadingReportingGroups" label="Reporting Groups">
          <el-cascader
            :value="reportingGroupValue"
            separator=" > "
            :options="reportingGroupOptions"
            :props="cascaderProps"
            clearable
            @change="handleReportingGroupChange"
          />
        </el-form-item>
      </el-col>
    </el-form>
  </div>
</template>

<script>
import PropertyFormAddress from './PropertyFormAddress'
import CraigslistAPI from '@/services/api/craigslist'
import ListInput from '@/components/forms/ListInput'
import { getFeatureSet } from '@/utils/subscriptions'
import { constants, enums } from '@/utils/constants'
import { validators } from '@/utils/element'
import { findGroup, findGroupWithProperty, removeEmptyChildrenFromGroups } from '@/utils/groups'

export default {
  name: 'PropertyFormGeneral',
  components: {
    'address-form': PropertyFormAddress,
    'list-input': ListInput
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      rules: {
        'property.name': [
          { required: true, message: 'This field is required', trigger: 'blur' },
          { max: 128, message: 'Name cannot be more than 128 characters', trigger: 'blur' }
        ],
        'property.legal_name': [
          { max: 128, message: 'Legal Name cannot be more than 128 characters', trigger: 'blur' }
        ],
        'property.website_url': [
          { type: 'url', message: 'Please provide a valid URL', trigger: 'blur' }
        ],
        'property.floor_plan_url': [
          { type: 'url', message: 'Please provide a valid URL', trigger: 'blur' }
        ],
        'property.all_available_units_url': [
          { type: 'url', message: 'Please provide a valid URL', trigger: 'blur' }
        ],
        'property.email_contact': [
          { type: 'email', message: 'Please provide a valid email address', trigger: 'blur' }
        ],
        'property.email_manager': [
          { type: 'email', message: 'Please provide a valid email address', trigger: 'blur' }
        ],
        'property.email_agency': [
          { type: 'email', message: 'Please provide a valid email address', trigger: 'blur' }
        ],
        'property.email_agency_decisionmaker': [
          { type: 'email', message: 'Please provide a valid email address', trigger: 'blur' }
        ],
        'property.phone_contact': [
          { max: 31, message: 'Phone number cannot be more than 31 characters', trigger: 'blur' }
        ],
        'property.property_address': [
          { required: true, validator: validators.emptyAddress, trigger: 'blur' }
        ],
        'property.unit_count': [
          { type: 'integer', message: 'Unit count must be an integer', trigger: 'blur' }
        ],
        'property.extra': [
          { validator: validators.json }
        ]
      },
      cascaderProps: {
        multiple: true,
        expandTrigger: 'hover',
        checkStrictly: true,
        emitPath: false,
        value: 'id',
        label: 'name'
      },
      usePropertyEmail: this.form.property.email_agency === this.form.property.email_contact,
      reportingGroupOptions: [],
      reportingGroupValue: [],
      phantomReportingGroup: null,
      loadingReportingGroups: false
    }
  },
  computed: {
    statusColor () {
      switch (this.form.property.status) {
        case 'active':
          return 'success'
        case 'inactive':
          return 'danger'
        case 'offboarding':
          return 'warning'
        case 'onboarding':
          return 'success'
        case 'pending':
          return ''
        case 'pre-trial':
          return 'primary'
        case 'auto-created':
          return 'info'
        default:
          return ''
      }
    },
    hasPostEngineSubscription () {
      const featureSet = getFeatureSet(this.form.property, status => ![enums.status.INACTIVE, enums.status.PENDING, enums.status.INVALID].includes(status))
      return featureSet.has(enums.features.POSTING) || featureSet.has(enums.features.POSTING_SERVICE)
    },
    hasFullService () {
      const featureSet = getFeatureSet(this.form.property, status => status !== enums.status.INACTIVE)
      return featureSet.has(enums.features.POSTING_SERVICE)
    }
  },
  watch: {
    form: {
      handler (newForm) {
        if (newForm.property.email_contact && this.usePropertyEmail) {
          this.form.property.email_agency = newForm.property.email_contact
        }
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    this.propertyTypes = constants.propertyTypes
    this.postingDayOptions = constants.postingDayOptions
    this.loadReportingGroups()
  },
  methods: {
    /**
     * Determine if the current form is valid.
     *
     * @returns {Promise}
     */
    validate () {
      const form = this.$refs['form']
      const addressFormComponent = this.$refs['address-form']

      return new Promise(resolve => {
        form.validate(async valid => {
          if (!valid) {
            return resolve(false)
          }

          form.clearValidate()
          addressFormComponent.$refs['form'].clearValidate()

          const addressFormValid = await addressFormComponent.validate()

          return resolve(addressFormValid)
        })
      })
    },
    /**
     * Fetch the reporting groups from the API as options in the cascader.
     */
    async loadReportingGroups () {
      try {
        this.loadingReportingGroups = true
        const params = { company_id: this.$route.params.cid }
        const reportingGroups = await CraigslistAPI.groups.list(params)
        // top level groups should always exist
        if (!reportingGroups.length) {
          throw new Error('Missing top level group(s). Please contact support.')
        }
        // remove and preserve the phantom group from the cascader values and options
        this.phantomReportingGroup = reportingGroups[0].id
        this.reportingGroupValue = this.form.property.postengine_groups.filter(id => id !== this.phantomReportingGroup)
        this.reportingGroupOptions = removeEmptyChildrenFromGroups(reportingGroups[0].children)
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loadingReportingGroups = false
      }
    },
    /**
     * Handles the logic to edit a property's reporting group(s) to
     * ensure that a property can only exist once per structure
     * and preserves the phantom group in the form data.
     *
     * @param {Array} value - selected group ids from the cascader.
     */
    handleReportingGroupChange (value) {
      const oldValue = this.form.property.postengine_groups
      const newValue = JSON.parse(JSON.stringify(value))
      const added = newValue.filter(id => !oldValue.includes(id))
      const removed = oldValue.filter(id => !newValue.includes(id) && id !== this.phantomReportingGroup)
      for (const group of added) {
        const addedGroup = findGroup(this.reportingGroupOptions, group)
        // traverse upward to get top level group
        let topLevelGroup = addedGroup
        while (topLevelGroup.parent.name) {
          topLevelGroup = findGroup(this.reportingGroupOptions, topLevelGroup.parent.id)
        }
        // find if any of the groups already contains the property
        const groupWithProperty = findGroupWithProperty([topLevelGroup], this.form.property.id)
        if (groupWithProperty) {
          // remove property from group
          newValue.splice(newValue.indexOf(groupWithProperty.id), 1)
          groupWithProperty.properties = groupWithProperty.properties.filter(property => property.id !== this.form.property.id)
        }
        addedGroup.properties.push({ id: this.form.property.id, name: this.form.property.name })
      }

      let showWarning = false
      for (const group of removed) {
        const removedGroup = findGroup(this.reportingGroupOptions, group)
        // traverse upward to get top level group
        let topLevelGroup = removedGroup
        while (topLevelGroup.parent.name) {
          topLevelGroup = findGroup(this.reportingGroupOptions, topLevelGroup.parent.id)
        }
        if (topLevelGroup.contains_all_properties) {
          const currentValue = oldValue.filter(id => id !== this.phantomReportingGroup)
          newValue.splice(currentValue.indexOf(group), 0, group)
          showWarning = true
        } else {
          removedGroup.properties = removedGroup.properties.filter(property => property.id !== this.form.property.id)
        }
      }
      if (showWarning) {
        this.$message({
          message: 'One or more group structure(s) must contain all properties and cannot be removed.',
          type: 'warning'
        })
      }

      this.reportingGroupValue = newValue
      // if property had phantom group, ensure it is put back in the form
      const formData = oldValue.includes(this.phantomReportingGroup) ? [this.phantomReportingGroup, ...newValue] : newValue
      this.$set(this.form.property, 'postengine_groups', formData)
    },
    /**
     * Copies activation code to the users clipboard
     *
     * @param {String} code - activation code to copy
     */
    async copyActivationCode (code) {
      try {
        await navigator.clipboard.writeText(code)
        this.$message({
          message: 'Successfully copied to clipboard!',
          type: 'success',
          duration: 3500
        })
      } catch {
        this.$message({
          message: 'Something went wrong',
          type: 'danger',
          duration: 3500
        })
      }
    },
    /**
     * Use property email as the Craigslist Agency contact email.
     */
    usePropertyEmailHandler (checked) {
      this.form.property.email_agency = checked ? this.form.property.email_contact : ''
    }
  }
}
</script>

<style lang="scss" scoped>
$color-primary: #1a7dc6;
$color-disabled: #bbb;
.el-cascader {
  width: 100%;
  ::v-deep .el-tag__close {
    display: none;
  }
}
h1:first-of-type {
  margin-top: 0;
}
.activation-code {
  ::v-deep .el-input-group__append {
    border-right: none;
    .el-button {
      background-color: #1a7dc6;
      color: #fff;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-top: -6px;

      &:disabled {
        background-color: #8dbee3;
      }
    }
  }
}
.property-address > ::v-deep.el-form-item__content {
  margin-left: 0 !important;
}
.el-alert {
  margin-bottom: 1em;
  background-color: rgba($color-primary, 0.2);
  color: $color-primary;
  b:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.full-service-content {
  position: relative;
  .disable-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #fff;
    opacity: 0.5;
  }
  section {
    &:not(:last-of-type) {
      margin-bottom: 2em;
    }
  }
}
</style>
