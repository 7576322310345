<template>
  <div id="property-form-subscriptions">
    <subscriptions-header
      :selected="selected"
      @on-cancel="showCancelDialog = true"
      @on-update="showUpdateDialog = true"
      @on-delete="handleDelete"
    />
    <div class="product-subscriptions">
      <subscription-table
        :data="form.property.product_subscriptions"
        :unit-count="form.property.unit_count"
        value-key="product"
        title="Products"
        @on-select="handleProductSelect"
        @on-delete="handleDelete"
      />
    </div>
    <div class="feature-subscriptions">
      <subscription-table
        :data="form.property.feature_subscriptions"
        :unit-count="form.property.unit_count"
        :premium="premium"
        value-key="feature"
        title="Add-Ons"
        @on-select="handleFeatureSelect"
        @on-delete="handleDelete"
      />
    </div>
    <div class="billing-summary">
      <el-row
        type="flex"
        justify="space-between"
        align="middle"
      >
        <el-col class="billing-summary-left" :span="8">
          <h3>Total Monthly Billed</h3>
        </el-col>

        <el-col class="billing-summary-right" :span="8">
          <p class="total-billing-rate">
            $ {{ getTotalMonthlyBillingRate() }}/Month
          </p>
          <p class="currency-info">
            All currencies are in {{ currencyCode }}
          </p>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-button
        type="text"
        @click="showBillingHistoryDialog = true"
      >
        View Billing History
      </el-button>
    </el-row>
    <div class="cancel">
      <el-row>
        <el-button
          type="danger"
          plain
          @click="cancelAll = true; showCancelDialog = true;"
        >
          Cancel Active Subscriptions
        </el-button>
      </el-row>
    </div>

    <form-dialog
      :show="showCancelDialog"
      :title="cancelDialogTitle"
      width="480px"
    >
      <cancel-form
        :subscriptions="selected"
        :cancel-all="cancelAll"
        @close="showCancelDialog = false; cancelAll = false;"
        @submit="handleCancel"
      />
    </form-dialog>

    <form-dialog
      :show="showUpdateDialog"
      title="Update Subscriptions"
      width="620px"
    >
      <update-form
        :subscriptions="selected"
        @close="showUpdateDialog = false"
        @submit="handleUpdate"
      />
    </form-dialog>

    <billing-history-dialog
      :show="showBillingHistoryDialog"
      :data="form"
      @close="showBillingHistoryDialog = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getStatus } from '@/utils/rooof'
import { enums } from '@/utils/constants'
import {
  getProductSubscriptions,
  getFeatureSubscriptions,
  getTotalMonthlyBillingRate
} from '@/utils/subscriptions'

import BillingHistoryDialog from '../../dialogs/BillingHistoryDialog'
import FormDialog from '@/components/dialogs/FormDialog'
import CancelForm from '../subscription/CancelForm'
import UpdateForm from '../subscription/UpdateForm'
import SubscriptionsHeader from '../../SubscriptionsHeader'
import SubscriptionTable from '../../SubscriptionTable'

export default {
  name: 'PropertyFormSubscriptions',
  components: {
    'billing-history-dialog': BillingHistoryDialog,
    'subscriptions-header': SubscriptionsHeader,
    'subscription-table': SubscriptionTable,
    'form-dialog': FormDialog,
    'cancel-form': CancelForm,
    'update-form': UpdateForm
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selectedProductSubscriptions: [],
      selectedFeatureSubscriptions: [],
      showCancelDialog: false,
      showUpdateDialog: false,
      showBillingHistoryDialog: false,
      cancelAll: false
    }
  },
  computed: {
    ...mapState({
      products: state => state.products,
      features: state => state.features
    }),
    /**
     * Merged list of selected product/features from tables.
     */
    selected () {
      if (this.cancelAll) {
        return [
          ...this.form.property.product_subscriptions,
          ...this.form.property.feature_subscriptions
        ]
      }
      return [
        ...this.selectedProductSubscriptions,
        ...this.selectedFeatureSubscriptions
      ]
    },
    cancelDialogTitle () {
      if (this.cancelAll) {
        return 'Cancel Active Subscriptions'
      }
      return 'Cancel Subscriptions'
    },
    title () {
      return this.selected.length > 1 ? 'Subscriptions' : 'Subscription'
    },
    /**
     * Get all features from active product subscriptions.
     */
    productSubscriptionFeatures () {
      let features = []
      const subscriptions = getProductSubscriptions(this.form.property, status =>
        [enums.status.ACTIVE, enums.status.OFFBOARDING].includes(status))
      for (const subscription of subscriptions) {
        const product = this.products.find(product => product.name === subscription.product)
        features = [ features, ...product.features ]
      }
      return features
    },
    /**
     * Get all features from active feature subscriptions.
     */
    featureSubscriptionFeatures () {
      const subscriptions = getFeatureSubscriptions(this.form.property, status =>
        [enums.status.ACTIVE, enums.status.OFFBOARDING].includes(status))
      return subscriptions.map(subscription => subscription.feature)
    },
    /**
     * Merge product and feature subscription features.
     */
    featureSet () {
      return [
        ...this.productSubscriptionFeatures,
        ...this.featureSubscriptionFeatures
      ]
    },
    /**
     * Aggregate duplicate features across all
     * product/feature subscriptions.
     */
    duplicateFeatureSet () {
      const set = new Set()
      const duplicates = new Set()

      for (const feature of this.featureSet) {
        if (!set.has(feature)) {
          set.add(feature)
        } else {
          duplicates.add(feature)
        }
      }
      return duplicates
    },
    currencyCode () {
      return this.$store.getters.getCompanyById(this.$route.params.cid).currency_code
    },
    /**
     * Flag for whether the property we are modifying has a valid
     * subscription for Premium Full Service for Craigslist.
     */
    premium () {
      const validStatuses = [enums.status.ACTIVE, enums.status.OFFBOARDING, enums.status.ONBOARDING, enums.status.PENDING, enums.status.TRIAL, enums.status.PRE_TRIAL]
      let productFeatures = []
      let subscriptions = getProductSubscriptions(this.form.property, status => validStatuses.includes(status))
      for (const subscription of subscriptions) {
        const product = this.products.find(product => product.name === subscription.product)
        productFeatures = [ productFeatures, ...product.features ]
      }
      subscriptions = getFeatureSubscriptions(this.form.property, status => validStatuses.includes(status))
      const features = subscriptions.map(subscription => subscription.feature)
      return [...productFeatures, ...features].includes(enums.features.PREMIUM_SERVICE)
    }
  },
  methods: {
    /**
     * Determine if subscriptions are valid. Called
     * when the stepper changes.
     *
     * @returns {Promise}
     */
    validate () {
      return new Promise(resolve => {
        const duplicates = Array.from(this.duplicateFeatureSet)
        const valid = duplicates.length === 0

        if (!valid) {
          this.$msgbox({
            title: 'Duplicate Features Detected',
            message: `Features must be unique across active subscriptions. Please update
                     or remove the offending subscriptions before continuing.
                     <br /><br /><b>Duplicates</b>: ${duplicates}`,
            type: 'error',
            dangerouslyUseHTMLString: true,
            showClose: false,
            closeOnClickModal: false,
            closeOnPressEscape: false
          })
        }
        return resolve(valid)
      })
    },
    /**
     * onSelect handler for product subscription table checkboxes.
     *
     * @param {Array} selected - subscription objects
     */
    handleProductSelect (selected) {
      this.selectedProductSubscriptions = selected
    },
    /**
     * onSelect handler for feature subscription table checkboxes.
     *
     * @param {Array} selected - subscription objects
     */
    handleFeatureSelect (selected) {
      this.selectedFeatureSubscriptions = selected
    },
    /**
     * Given a subscription, update the status based on
     * the billing dates.
     *
     * @param {Object} subscription
     */
    updateStatus (subscription) {
      this.$set(subscription, 'status', getStatus(
        subscription.trial_start_date,
        subscription.trial_end_date,
        subscription.start_date,
        subscription.end_date
      ))
    },
    /**
     * onCancel handler for cancel subscription form submit.
     *
     * @param {Object} data
     * @param {String} data.end_date
     * @param {String} data.reason_for_cancel
     * @param {String} data.reason_for_cancel_other
     */
    handleCancel (data) {
      const uncancellable = [
        enums.status.PENDING,
        enums.status.INACTIVE,
        enums.status.INVALID
      ]
      for (const subscription of this.selected) {
        // don't cancel pending, inactive, and invalid subscriptions
        if (uncancellable.includes(subscription.status)) {
          continue
        }
        // Cancel subscription
        if (subscription.status === enums.status.PRE_TRIAL ||
          subscription.status === enums.status.TRIAL) {
          this.$set(subscription, 'trial_end_date', data.end_date)
        } else {
          this.$set(subscription, 'end_date', data.end_date)
        }
        this.$set(subscription, 'reason_for_cancel', data.reason_for_cancel)
        this.$set(subscription, 'reason_for_cancel_other', data.reason_for_cancel_other)
        this.updateStatus(subscription)
      }
    },
    /**
     * onDelete handler for action menu.
     *
     * @param {Array} selected
     */
    handleDelete (selected) {
      const message = `This will permanently delete <b>${selected.length}</b> ${this.title.toLowerCase()}.
                      <br />Are you sure you wish to continue?`

      this.$confirm(message, {
        title: `Delete ${this.title}`,
        type: 'warning',
        dangerouslyUseHTMLString: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(() => {
        for (const subscription of selected) {
          const type = subscription.hasOwnProperty('product') ? 'product' : 'feature'
          const subscriptions = this.form.property[`${type}_subscriptions`]
          const index = subscriptions.findIndex(s => s.uid === subscription.uid)
          this.$delete(subscriptions, index)
        }
      }).catch(() => {
        // do nothing on cancel
      })
    },
    /**
     * onUpate handler for action menu.
     *
     * @param {Array} fieldsToUpdate
     */
    handleUpdate (fieldsToUpdate) {
      for (const subscription of this.selected) {
        for (const { field, value } of fieldsToUpdate) {
          this.$set(subscription, field, value)
        }
        this.updateStatus(subscription)
      }
    },
    /**
     * @return {String} Monthly Revenue from Property
     */
    getTotalMonthlyBillingRate () {
      return getTotalMonthlyBillingRate(this.form.property)
    }
  }
}
</script>

<style scoped>
.currency-info {
  font-style:  italic;
  color: #909399;
}
.billing-summary {
  margin-top: 50px;
  border: 1px solid #e6e6e6;
  border-radius: 7px;
  width: 50%;
  padding: 0 10px;
}
.billing-summary p {
  margin: 2px;
}
.billing-summary h3 {
  color: #606266;
}
.billing-summary-right {
  text-align: right;
}
.total-billing-rate {
  font-weight: bold;
}
.cancel {
  margin-top: 1em;
}
</style>
