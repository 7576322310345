<template>
  <div id="subscription-table">
    <div class="header">
      <div class="title">
        {{ title }}
      </div>
      <div>
        <el-button
          size="mini"
          icon="el-icon-plus"
          @click="showSubscriptionForm()"
        >
          Add {{ valueKey }}
        </el-button>
      </div>
    </div>
    <el-table
      ref="subscription-table"
      :data="data"
      :row-class-name="setInactiveClass"
      :default-sort="{ prop: 'status' }"
      @selection-change="handleSelect"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="Name">
        <template slot-scope="scope">
          <div class="name">
            {{ scope.row[valueKey] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="Status"
        width="150"
        prop="status"
        :sort-method="sortByStatus"
      >
        <template slot-scope="scope">
          <el-tag
            size="medium"
            :type="getStatusTagType(scope.row.status)"
            disable-transitions
          >
            {{ scope.row.status }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Monthly Rate" width="130">
        <template slot-scope="scope">
          {{ '$' + getRateValue(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column label="Discount" width="130">
        <template slot-scope="scope">
          {{ getDiscountValue(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column label="Monthly Total" width="130">
        <template slot-scope="scope">
          {{ '$' + getTotalValue(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column label="Billing Frequency" width="150">
        <template slot-scope="scope">
          {{ getBillingFrequency(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column label="Subscription Period">
        <template slot-scope="scope">
          <div v-if="scope.row.start_date">
            {{ scope.row.start_date | dateString }}
            <i class="el-icon-right" />
            {{ scope.row.end_date | dateString }}
          </div>
          <div v-else>
            (none)
          </div>
        </template>
      </el-table-column>
      <el-table-column type="expand" width="30">
        <template slot-scope="scope">
          <subscription-details :data="scope.row" :value-key="valueKey" />
        </template>
      </el-table-column>
      <el-table-column width="50">
        <template slot-scope="scope">
          <el-dropdown trigger="click" @command="handleCommand">
            <span>
              <i class="el-icon-more" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{ action: 'edit', obj: scope.row }">
                Edit
              </el-dropdown-item>
              <el-dropdown-item
                v-if="!['inactive', 'offboarding', 'pending'].includes(scope.row.status)"
                :command="{ action: 'cancel', obj: scope.row }"
              >
                Cancel
              </el-dropdown-item>
              <el-dropdown-item :command="{ action: 'delete', obj: scope.row }">
                Delete
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <el-button
      v-if="hasInactiveSubscriptions"
      type="text"
      @click="showInactiveSubscriptions = !showInactiveSubscriptions"
    >
      {{ showInactiveSubscriptions ? 'Hide' : 'Show' }} Inactive Subscriptions
      <i :class="showInactiveSubscriptions ? 'el-icon-arrow-down' : 'el-icon-arrow-right'" />
    </el-button>

    <edit-dialog
      :show="showSubscriptionDialog"
      :subscription="subscription"
      :subscription-type="valueKey"
      :unit-count="unitCount"
      :premium="premium"
      @add-subscription="handleAddSubscription"
      @edit-subscription="handleEditSubscription"
      @close="showSubscriptionDialog = false"
    />

    <form-dialog :show="showCancelDialog" title="Cancel Subscriptions">
      <cancel-form
        :subscriptions="[ subscription ]"
        @close="showCancelDialog = false"
        @submit="handleCancelSubscription"
      />
    </form-dialog>
  </div>
</template>

<script>
/**
 * SubscriptionTable
 *
 * This component renders the product/feature subscription table.
 * Each table row represents a difference subscription; users can
 * use the action buttons on the right to view details about a
 * specific subscription, or to see a menu of actions which can
 * be performed (edit/delete).
 *
 * The valueKey prop determines which type of subscription the
 * table is displaying (product, feature). They both share the
 * same underlying schema, so the majority of table fields are
 * the same.
 *
 * Events:
 *
 * @on-select: triggers when user selects row(s) using checkbox
 * @on-delete: triggers when user deletes a subscription using
 *             the action menu
 */
import { getStatus, getStatusTagType } from '@/utils/rooof'
import SubscriptionDetails from './SubscriptionDetails'
import EditActionDialog from './dialogs/EditActionDialog'
import FormDialog from '@/components/dialogs/FormDialog'
import CancelForm from './forms/subscription/CancelForm'
import { constants, enums } from '@/utils/constants'
import { getTotalValue } from '@/utils/subscriptions'

import moment from 'moment'

export default {
  name: 'SubscriptionTable',
  filters: {
    dateString (value) {
      if (!value) {
        return 'Ongoing'
      }
      return moment(value).format('MMM DD, YYYY')
    }
  },
  components: {
    'subscription-details': SubscriptionDetails,
    'edit-dialog': EditActionDialog,
    'form-dialog': FormDialog,
    'cancel-form': CancelForm
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    unitCount: {
      type: Number,
      default: undefined
    },
    valueKey: {
      type: String,
      required: true,
      validator: value => {
        return value === 'product' || value === 'feature'
      }
    },
    title: {
      type: String,
      required: true
    },
    premium: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showActionMenu: this.data.map(() => false),
      showSubscriptionDialog: false,
      showCancelDialog: false,
      subscription: null, // edit/cancel target
      showInactiveSubscriptions: false
    }
  },
  computed: {
    hasInactiveSubscriptions () {
      return this.data.some(subscription => subscription.status === enums.status.INACTIVE)
    }
  },
  created () {
    this.getStatusTagType = getStatusTagType
    this.getTotalValue = getTotalValue
  },
  methods: {
    /**
     * onSelect handler for table row checkboxes.
     *
     * @param {Array} selection - list of selected row objects
     */
    handleSelect (selection) {
      this.$emit('on-select', selection)
    },
    /**
     * Get the value to display in `rate` table column.
     *
     * @param {Object} row
     */
    getRateValue (row) {
      const rate = parseFloat(row.rate).toFixed(2)
      return row.low_density ? `${rate} (low-density)` : rate
    },
    /**
     * Get the value to display in `discount` table column.
     *
     * @param {Object} row
     */
    getDiscountValue (row) {
      if (!row.discount) {
        return null
      }
      switch (row.discount_type) {
        case '$':
          return '$' + row.discount
        case '%':
          return row.discount + '%'
        default:
          return null
      }
    },
    /**
     * Get the value to display in the billing frequency column
     *
     * @param {Object} subscription
     */
    getBillingFrequency (subscription) {
      return constants.billingFrequency.find(el => {
        return el.value === subscription.billing_frequency
      }).label
    },
    /**
     * onSubmit handler for new product/feature form submit.
     *
     * @param {Object} subscription
     */
    handleAddSubscription (subscription) {
      this.data.push(subscription)
    },
    /**
     * onSubmit handler for edit product/feature form submit.
     *
     * @param {Object} subscription
     */
    handleEditSubscription (subscription) {
      const index = this.data.findIndex(s => s.uid === subscription.uid)
      this.$set(this.data, index, subscription)
    },
    /**
     * onSubmit handler for cancel subscription form submit.
     *
     * @param {Object} data
     * @param {String} data.end_date
     * @param {String} data.reason_for_cancel
     * @param {String} data.reason_for_cancel_other
     */
    handleCancelSubscription (data) {
      this.$set(this.subscription, 'end_date', data.end_date)
      this.$set(this.subscription, 'reason_for_cancel', data.reason_for_cancel)
      this.$set(this.subscription, 'reason_for_cancel_other', data.reason_for_cancel_other)
      this.updateStatus(this.subscription)
    },
    /**
     * onClick handler for action menu dropdown item select.
     *
     * @param {Object} command
     * @param {String} command.action - action to execute
     * @param {Object} command.obj - target subscription
     */
    handleCommand (command) {
      switch (command.action) {
        case 'edit':
          this.showSubscriptionForm(command.obj)
          break
        case 'cancel':
          this.subscription = command.obj
          this.showCancelDialog = true
          break
        case 'delete':
          this.$emit('on-delete', [command.obj])
          break
      }
    },
    /**
     * Display the form to create/update a subscription.
     *
     * @param {Object} [subscription] - subscription object (edit only)
     */
    showSubscriptionForm (subscription = null) {
      this.subscription = subscription
      this.showSubscriptionDialog = true
    },
    /**
     * Given a subscription, update the status based on
     * the billing dates.
     *
     * @param {Object} subscription
     */
    updateStatus (subscription) {
      this.$set(subscription, 'status', getStatus(
        subscription.trial_start_date,
        subscription.trial_end_date,
        subscription.start_date,
        subscription.end_date
      ))
    },
    /**
     * Returns the CSS class for when a row should be shown/hidden
     *
     * @param {Object} scope
     * @returns {String}
     */
    setInactiveClass (scope) {
      return !this.showInactiveSubscriptions && scope.row.status === enums.status.INACTIVE
        ? 'hide-row' : ''
    },
    /**
     * Sort method for subscription status.
     * Sort inactive subscriptions last.
     */
    sortByStatus (a, b) {
      if (a.status === enums.status.INACTIVE && b.status !== enums.status.INACTIVE) {
        return 1
      }
      if (b.status === enums.status.INACTIVE && a.status !== enums.status.INACTIVE) {
        return -1
      }
      return 0
    }
  }
}
</script>

<style scoped>
#subscription-table {
  margin-bottom: 20px;
}
.header {
  display: flex;
  justify-content: space-between;
}
.title {
  padding-top: 15px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}
.name {
  font-size: 14px;
}
.el-icon-more {
  transform: rotate(90deg);
  padding: 5px;
}
.el-icon-more:hover {
  cursor: pointer;
}

::v-deep .hide-row {
  display: none;
}
</style>
