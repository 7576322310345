<template>
  <div id="billing-history-dialog">
    <el-dialog
      :visible="show"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      :show-close="true"
      title="Subscription Change Logs"
      width="70%"
      @close="$emit('close')"
    >
      <el-table
        v-loading="loading"
        :data="history"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <pre>{{ formatChanges(props.row.changes) }}</pre>
          </template>
        </el-table-column>
        <el-table-column
          label="Subscription"
          min-width="200"
        >
          <template slot-scope="props">
            {{ getSubscription(props.row.object_id, props.row.object_type) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="timestamp"
          label="Modified On"
          min-width="200"
        >
          <template slot-scope="props">
            {{ props.row.timestamp | dateTimeString }}
          </template>
        </el-table-column>
        <el-table-column
          prop="modified_by"
          label="Modified By"
          min-width="150"
        />
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import RooofAPI from '@/services/api/rooof'

export default {
  name: 'BillingHistoryDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: undefined
    }
  },
  data () {
    return {
      history: [],
      loading: false
    }
  },
  async created () {
    try {
      this.loading = true
      if (this.$route.params.id) {
        this.history = await RooofAPI.properties.billingHistory(this.$route.params.id)
      }
    } catch (err) {
      const details = err.response ? err.response.data : null
      this.$rfAlert.error(this, err.toString(), details)
    } finally {
      this.loading = false
    }
  },
  methods: {
    /**
     * Look for the subscription object which matches
     * the given id/type.
     *
     * @param {Number} id
     * @param {String} type - 'product' or 'feature'
     */
    getSubscription (id, type) {
      if (this.data) {
        for (const subscription of this.data.property[`${type}_subscriptions`]) {
          if (id === subscription.id) {
            return subscription[type]
          }
        }
        return '[deleted]'
      }
    },
    /**
     * Format changes object as a human-readable string.
     */
    formatChanges (data) {
      return Object.keys(data).map(key => {
        const formattedKey = key.split('_').map(this.capitalize).join(' ')
        return `${formattedKey}: ${data[key] === null ? '(none)' : data[key]}`
      }).join('\n')
    },
    /**
     * Given a word, capitalize the first letter.
     *
     * @param {String} word
     * @returns {String}
     */
    capitalize (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    }
  }
}
</script>

<style>
#billing-history-dialog .el-dialog__body {
  padding: 20px;
}
#billing-history-dialog .el-table__expanded-cell {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
