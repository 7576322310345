<template>
  <div id="base-property-form" v-loading="loading">
    <el-row
      type="flex"
      justify="space-between"
      align="middle"
    >
      <slot name="title" />
      <div>
        <slot name="buttons" />
        <el-button
          id="submit-btn"
          size="medium"
          type="success"
          :disabled="!dataModified"
          @click="submit"
        >
          Save Changes
        </el-button>
        <el-dropdown
          trigger="click"
          @command="handleDropdownSelect"
        >
          <el-button
            size="medium"
            icon="el-icon-more"
          >
            <i class="el-icon-arrow-down el-icon--right" />
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="hasPostingService" command="agency">
              Agency Posts
            </el-dropdown-item>
            <el-dropdown-item command="reporting">
              Dashboard Reporting
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-row>
    <el-tabs
      v-model="activeTab"
      :before-leave="validateForm"
    >
      <el-tab-pane
        v-for="tab in tabs"
        :key="tab.name"
        :label="tab.label"
        :name="tab.name"
      />
      <keep-alive>
        <div class="form-wrapper">
          <component
            :is="activeTabComponent"
            ref="activeTab"
            :form="data"
            :company="company"
            @submit="$emit('submit', $event)"
          />
        </div>
      </keep-alive>
    </el-tabs>
  </div>
</template>

<script>
/**
 * BasePropertyForm
 *
 * Renders property data as a series of tabs, each containing
 * a subset of fields in a form. Each form is treated as individual
 * from the rest; users must submit form data if anything has
 * changed before they will be permitted to navigate to a new tab.
 *
 * Form components are responsible for rendering a submit button
 * and performing any necessary validation.
 *
 * If the form component contains a `validate` method it will
 * also be called in the before-leave tab hook; if the validation
 * returns false, navigation will be prevented.
 *
 * Events:
 *
 * @submit: propagate submit event from child component
 */
import PropertyFormGeneral from './forms/property/PropertyFormGeneral'
import PropertyFormLeadTracking from './forms/property/PropertyFormLeadTracking/PropertyFormLeadTracking'
import PropertyFormBilling from './forms/property/PropertyFormBilling'
import PropertyFormSubscriptions from './forms/property/PropertyFormSubscriptions'
import PropertyFormPosting from './forms/property/PropertyFormPosting'
import PropertyFormIntegrations from './forms/property/PropertyFormIntegrations'
import PropertyFormNotes from './forms/property/PropertyFormNotes'
import LinkButton from '@/components/buttons/LinkButton'
import { filterEmptyValues } from '@/utils'
import { enums } from '@/utils/constants'
import { getFeatureSet } from '@/utils/subscriptions'

export default {
  name: 'BasePropertyForm',
  components: {
    LinkButton
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    company: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      activeTab: 'PropertyFormGeneral',
      tabs: [
        { label: 'General', name: 'PropertyFormGeneral', component: PropertyFormGeneral },
        { label: 'Lead Tracking', name: 'PropertyFormLeadTracking', component: PropertyFormLeadTracking },
        { label: 'Billing', name: 'PropertyFormBilling', component: PropertyFormBilling },
        { label: 'Subscriptions', name: 'PropertyFormSubscriptions', component: PropertyFormSubscriptions },
        { label: 'Posting', name: 'PropertyFormPosting', component: PropertyFormPosting },
        { label: 'Integrations', name: 'PropertyFormIntegrations', component: PropertyFormIntegrations },
        { label: 'Notes', name: 'PropertyFormNotes', component: PropertyFormNotes }
      ]
    }
  },
  computed: {
    activeTabComponent () {
      return this.tabs.find(tab => tab.name === this.activeTab).component
    },
    dataModified () {
      // Sanitize fields
      const data = JSON.parse(JSON.stringify(this.data))
      const dataCopy = JSON.parse(JSON.stringify(this.dataCopy))

      for (const field in data) {
        if (Array.isArray(data[field])) {
          data[field] = filterEmptyValues(data[field])
          dataCopy[field] = filterEmptyValues(dataCopy[field])
        }
      }
      for (const field in data.property) {
        if (Array.isArray(data.property[field])) {
          data.property[field] = filterEmptyValues(data.property[field])
          dataCopy.property[field] = filterEmptyValues(dataCopy.property[field])
        }
      }

      return JSON.stringify(data) !== JSON.stringify(dataCopy)
    },
    hasPostingService () {
      if (!this.data.property.features_list) {
        return false
      }
      const featureSet = getFeatureSet(this.data.property, status => ![enums.status.INACTIVE, enums.status.PRE_TRIAL, enums.status.PENDING, enums.status.INVALID].includes(status))
      return featureSet.has(enums.features.POSTING_SERVICE)
    }
  },
  watch: {
    data: {
      handler () {
        this.dataCopy = JSON.parse(JSON.stringify(this.data))
      },
      immediate: true
    }
  },
  created () {
    window.addEventListener('beforeunload', this.handleUnload)
  },
  destroyed () {
    window.removeEventListener('beforeunload', this.handleUnload)
  },
  methods: {
    /**
     * Validate the form being actively viewed.
     *
     * @returns {(Promise|Boolean)}
     */
    validateForm () {
      const validate = this.$refs['activeTab'].validate
      if (validate && typeof validate === 'function') {
        return validate().then(result => {
          return result || Promise.reject(new Error('Form validation failed'))
        })
      }
      return true
    },
    /**
     * Validate form and emit submit event to be handled
     * by the parent.
     */
    async submit () {
      try {
        await this.validateForm()
        this.$emit('submit', this.data)
        this.dataCopy = JSON.parse(JSON.stringify(this.data))
      } catch (err) {
        // form invalid, do nothing
      }
    },
    /**
     * onbeforeunload handler, warns the user when they have have
     * modified data but not saved.
     *
     * @param {object} event
     * @returns {string|null} Message to display to user (ignored by all modern browsers), or null to cancel
     */
    handleUnload (event) {
      if (this.dataModified) {
        const msg = 'Data on this page may not be saved. Continue?'
        event.returnValue = msg
        return msg
      }
      return null
    },
    /**
     * Handler for when an item from the dropdown is clicked.
     *
     * @param {String} command
     */
    handleDropdownSelect (command) {
      const agencyLink = this.$router.resolve({ name: 'FullServicePosts', params: { id: this.$route.params.id } })

      switch (command) {
        case 'agency':
          window.open(agencyLink.href, '_blank')
          break
        case 'reporting':
          window.open(
            `${process.env.VUE_APP_PORTAL_URL}companies/${this.$route.params.cid}/properties/${this.$route.params.id}/reporting/overview`
          )
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-wrapper {
  padding: 15px;

  ::v-deep form.el-form {
    width: 60%;
  }

  ::v-deep .el-dialog form.el-form {
    width: 100%;
  }
}
.el-dropdown {
  margin-left: 10px;
}
</style>
