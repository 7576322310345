<template>
  <div id="cancel-form">
    <div v-if="!cancelAll" class="form-tip">
      You are about to cancel <b>{{ subscriptions.length }}</b> subscriptions.
      When choosing an End Date, it must fall after the Start Date
      of each subscription being cancelled.
    </div>
    <el-form
      ref="cancel-form"
      :model="form"
      :rules="rules"
      label-width="134px"
    >
      <el-form-item
        label="End Date"
        prop="end_date"
      >
        <el-date-picker
          v-model="form.end_date"
          :picker-options="pickerOptions"
          type="date"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          placeholder="Choose date..."
        />
      </el-form-item>
      <el-form-item
        label="Reason for Cancel"
        prop="reason_for_cancel"
      >
        <el-select
          v-model="form.reason_for_cancel"
          filterable
          clearable
        >
          <el-option
            v-for="reason in reasonForCancel"
            :key="reason.value"
            :label="reason.label"
            :value="reason.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.reason_for_cancel === 'other'"
        label="Please specify"
        prop="reason_for_cancel_other"
      >
        <el-input v-model="form.reason_for_cancel_other" />
      </el-form-item>

      <div class="form-controls">
        <el-button @click="cancel">
          Cancel
        </el-button>
        <el-button type="primary" @click="submit">
          <span v-if="!cancelAll">
            Confirm
          </span>
          <span v-else>
            Set to Inactive
          </span>
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
/**
 * CancelForm
 *
 * Retrieves values from user which can be used
 * to batch cancel product/feature subscriptions.
 *
 * Events:
 *
 * @close: triggers on cancel
 * @submit: triggers on form submit
 */
import { constants } from '@/utils/constants'

export default {
  name: 'CancelForm',
  props: {
    subscriptions: {
      type: Array,
      required: true
    },
    cancelAll: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rules: {
        end_date: [
          { required: true, message: 'This field is required', trigger: 'blur' }
        ],
        reason_for_cancel: [
          { required: true, message: 'This field is required', trigger: 'blur' }
        ],
        reason_for_cancel_other: [
          { required: true, message: 'This field is required', trigger: 'blur' }
        ]
      },
      pickerOptions: {
        disabledDate: date => {
          return date <= this.latestStartDate
        }
      },
      form: {
        end_date: '',
        reason_for_cancel: '',
        reason_for_cancel_other: ''
      }
    }
  },
  computed: {
    /**
     * Find the most recent start_date of all subscriptions being cancelled.
     * The selected end_date must be after this value.
     *
     * @returns {?Number} unix timestamp
     */
    latestStartDate () {
      let latest = null
      for (const subscription of this.subscriptions) {
        if (subscription && subscription.start_date) {
          const current = this.$moment(subscription.start_date).endOf('day')
          if (current > latest) {
            latest = current
          }
        }
        if (subscription && subscription.trial_start_date) {
          const current = this.$moment(subscription.trial_start_date).endOf('day')
          if (current > latest) {
            latest = current
          }
        }
      }
      return latest ? latest.valueOf() : null
    }
  },
  created () {
    this.constants = constants
    const toRemove = ['subscription-change', 'switching-to-manual']
    this.reasonForCancel = this.constants.reasonForCancel.filter((el) =>
      !toRemove.includes(el.value)
    )
  },
  methods: {
    /**
     * onClick handler for submit button.
     * Emits 'submit' event if form data is valid.
     */
    submit () {
      this.$refs['cancel-form'].validate(valid => {
        if (!valid) {
          return
        }
        // Send a copy, resetFields() will clear existing data
        this.$emit('submit', JSON.parse(JSON.stringify(this.form)))
        this.$emit('close')
        this.reset()
      })
    },
    /**
     * onClick handler for cancel button.
     * Resets form fields and emits 'close' event.
     */
    cancel () {
      this.$emit('close')
      this.reset()
    },
    /**
     * Reset form to default values.
     */
    reset () {
      this.$refs['cancel-form'].resetFields()
    }
  }
}
</script>

<style scoped>
.form-controls {
  display: flex;
  justify-content: flex-end;
}
.form-tip {
  word-break: normal;
  padding-bottom: 20px;
}
.el-date-editor, .el-select{
  width: 100%;
}
</style>
